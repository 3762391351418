import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { useHistory } from "react-router-dom";
import { MdOutlineProductionQuantityLimits } from "react-icons/md";
import { GrUserWorker } from "react-icons/gr";
import { Paper } from "@material-ui/core";
import { FaPen, FaFileAlt, FaCertificate, FaDollarSign, FaLock, FaMoneyBillAlt } from 'react-icons/fa';
import { AiOutlineEdit, AiOutlineCheck, AiOutlineCloseCircle } from 'react-icons/ai';
import { MdSend, MdCheckCircle } from 'react-icons/md';
import { IoIosEye } from 'react-icons/io';
import { IoNavigateOutline } from "react-icons/io5";

const useStyles = makeStyles((theme) => ({
    loaderCont: {
        width: "100%",
        height: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center"
    },
    cardsCont: {
        width: "100%",
        display: "flex",
        justifyContent: "space-between"
    },
    singleCardCont: {
        width: "49%"
    },
    mainSingleCardCont: {
        width: "100%",
        padding: "15px",
        "&:hover": {
            transform: "scale(1.02)",
        },
    },
    amountsCont: {
        width: "100%",
        display: "flex",
        alignItems: "center",
        marginBottom: "10px"
    },
    cardMainData: {
        "& h3": {
            fontSize: "20px",
            fontWeight: "700",
            color: "gray"
        },
        "& p": {
            fontSize: "18px",
            fontWeight: "600"
        },
    },
    mainIconCont: {
        width: "60px",
        height: "60px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        borderRadius: "50%",
        marginRight: "15px",
        "& svg": {
            fontSize: "40px",
            color: "white"
        }
    },
    subTotalsCont: {
        width: "100%",
        display: "flex",
        overflowX: "auto",
        "&::-webkit-scrollbar": {
            display: "none",
        },
        scrollbarWidth: "none",
    },
    subTotalSingleCont: {
        display: "flex",
        alignItems: "center",
        marginRight: "20px",
        flexGrow: "1",
        flexShrink: "0",
        "& h3": {
            fontSize: "12px",
            fontWeight: "500",
            color: "gray"
        },
        "& p": {
            fontSize: "10px",
            fontWeight: "500"
        }
    },
    subIconSty: {
        width: "20px",
        height: "20px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: "blue",
        borderRadius: "50%",
        marginRight: "10px",
        "& svg": {
            fontSize: "12px",
            color: "white"
        }
    }
}));

export default function WorkorderPurchaseTotalCard({
    cardType, mainTotal, draftAmount, submittedAmount,
    approvedAmount, issuedAmount, acknowledgedAmount,
    fullyFulfilledAmount, partiallyFulfilledAmount,
    rejectedAmount, certifiedAmount, paidAmount,
    retentionDeductionAmount, advanceDeductionAmount,
    retentionReleaseAmount
}) {
    const history = useHistory();
    const classes = useStyles();
    const dispatch = useDispatch();
    const theme = useTheme();

    function numberWithCommas(x) {
        return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }

    return (<div className={classes.singleCardCont} >
        <Paper className={classes.mainSingleCardCont} elevation={2} >
            <div className={classes.amountsCont} >
                <div
                    className={classes.mainIconCont}
                    style={{ backgroundColor: cardType === "Purchase Order" ? "#DC143C" : "#6495ED" }}
                >
                    {cardType === "Purchase Order" ? (
                        <MdOutlineProductionQuantityLimits />
                    ) : (
                        <GrUserWorker />
                    )}
                </div>
                <div className={classes.cardMainData} >
                    <h3>{cardType}</h3>
                    <p>₹{numberWithCommas(parseFloat(mainTotal || 0).toFixed(2))}</p>
                </div>
            </div>

            <div className={classes.subTotalsCont} >
                <div className={classes.subTotalSingleCont} >
                    <div className={classes.subIconSty} style={{ backgroundColor: "gray" }}  >
                        <FaPen />
                    </div>
                    <div>
                        <h3>Draft</h3>
                        <p>₹{numberWithCommas(parseFloat(draftAmount || 0).toFixed(2))}</p>
                    </div>
                </div>
                <div className={classes.subTotalSingleCont} >
                    <div className={classes.subIconSty} style={{ backgroundColor: "blue" }}  >
                        <MdSend />
                    </div>
                    <div>
                        <h3>Submitted</h3>
                        <p>₹{numberWithCommas(parseFloat(submittedAmount || 0).toFixed(2))}</p>
                    </div>
                </div>
                <div className={classes.subTotalSingleCont} >
                    <div className={classes.subIconSty} style={{ backgroundColor: "green" }}  >
                        <MdCheckCircle />
                    </div>
                    <div>
                        <h3>Approved</h3>
                        <p>₹{numberWithCommas(parseFloat(approvedAmount || 0).toFixed(2))}</p>
                    </div>
                </div>
                <div className={classes.subTotalSingleCont} >
                    <div className={classes.subIconSty} style={{ backgroundColor: "purple" }}  >
                        <FaLock />
                    </div>
                    <div>
                        <h3>Retention Deduction</h3>
                        <p>₹{numberWithCommas(parseFloat(retentionDeductionAmount || 0).toFixed(2))}</p>
                    </div>
                </div>
                <div className={classes.subTotalSingleCont} >
                    <div className={classes.subIconSty} style={{ backgroundColor: "green" }}  >
                        <IoNavigateOutline />
                    </div>
                    <div>
                        <h3>Retention Release</h3>
                        <p>₹{numberWithCommas(parseFloat(retentionReleaseAmount || 0).toFixed(2))}</p>
                    </div>
                </div>
                <div className={classes.subTotalSingleCont} >
                    <div className={classes.subIconSty} style={{ backgroundColor: "brown" }}  >
                        <FaMoneyBillAlt />
                    </div>
                    <div>
                        <h3>Advance Deduction</h3>
                        <p>₹{numberWithCommas(parseFloat(advanceDeductionAmount || 0).toFixed(2))}</p>
                    </div>
                </div>
                <div className={classes.subTotalSingleCont} >
                    <div className={classes.subIconSty} style={{ backgroundColor: "orange" }}  >
                        <FaFileAlt />
                    </div>
                    <div>
                        <h3>Issued</h3>
                        <p>₹{numberWithCommas(parseFloat(issuedAmount || 0).toFixed(2))}</p>
                    </div>
                </div>
                <div className={classes.subTotalSingleCont} >
                    <div className={classes.subIconSty} style={{ backgroundColor: "teal" }}  >
                        <IoIosEye />
                    </div>
                    <div>
                        <h3>Acknowledged</h3>
                        <p>₹{numberWithCommas(parseFloat(acknowledgedAmount || 0).toFixed(2))}</p>
                    </div>
                </div>
                <div className={classes.subTotalSingleCont} >
                    <div className={classes.subIconSty} style={{ backgroundColor: "darkgreen" }}  >
                        <AiOutlineCheck />
                    </div>
                    <div>
                        <h3>Fully Fulfilled</h3>
                        <p>₹{numberWithCommas(parseFloat(fullyFulfilledAmount || 0).toFixed(2))}</p>
                    </div>
                </div>
                <div className={classes.subTotalSingleCont} >
                    <div className={classes.subIconSty} style={{ backgroundColor: "yellow" }}  >
                        <AiOutlineEdit />
                    </div>
                    <div>
                        <h3>Partially Fulfilled</h3>
                        <p>₹{numberWithCommas(parseFloat(partiallyFulfilledAmount || 0).toFixed(2))}</p>
                    </div>
                </div>
                <div className={classes.subTotalSingleCont} >
                    <div className={classes.subIconSty} style={{ backgroundColor: "red" }}  >
                        <AiOutlineCloseCircle />
                    </div>
                    <div>
                        <h3>Rejected</h3>
                        <p>₹{numberWithCommas(parseFloat(rejectedAmount || 0).toFixed(2))}</p>
                    </div>
                </div>
                <div className={classes.subTotalSingleCont} >
                    <div className={classes.subIconSty} style={{ backgroundColor: "gold" }}  >
                        <FaCertificate />
                    </div>
                    <div>
                        <h3>Certified</h3>
                        <p>₹{numberWithCommas(parseFloat(certifiedAmount || 0).toFixed(2))}</p>
                    </div>
                </div>
                <div className={classes.subTotalSingleCont} >
                    <div className={classes.subIconSty} style={{ backgroundColor: "teal" }}  >
                        <FaDollarSign />
                    </div>
                    <div>
                        <h3>Paid</h3>
                        <p>₹{numberWithCommas(parseFloat(paidAmount || 0).toFixed(2))}</p>
                    </div>
                </div>
            </div>
        </Paper>
    </div>);
}