import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { useHistory } from "react-router-dom";
import CircularProgress from '@material-ui/core/CircularProgress';
import { getProcurementsAggregatedAmounts } from "./api.call";
import { MdOutlineProductionQuantityLimits } from "react-icons/md";
import { GrUserWorker } from "react-icons/gr";
import { Paper } from "@material-ui/core";
import { FaPen, FaPaperPlane, FaFileAlt, FaHandshake, FaCertificate, FaDollarSign } from 'react-icons/fa';
import { AiOutlineEdit, AiOutlineCheck, AiOutlineCloseCircle } from 'react-icons/ai';
import { MdSend, MdCheckCircle, MdInsertDriveFile } from 'react-icons/md';
import { IoIosEye } from 'react-icons/io';
import WorkorderPurchaseTotalCard from "./WorkorderPurchaseTotalCard";
import AmountStatusChart from "./AmountStatusChart";

const useStyles = makeStyles((theme) => ({
    loaderCont: {
        width: "100%",
        height: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center"
    },
    cardsCont: {
        width: "100%",
        display: "flex",
        justifyContent: "space-between"
    },
    singleCardCont: {
        width: "50%",
        padding: "10px"
    },
    mainSingleCardCont: {
        width: "100%",
        padding: "15px"
    },
    amountsCont: {
        width: "100%",
        display: "flex",
        alignItems: "center",
        marginBottom: "10px"
    },
    cardMainData: {
        "& h3": {
            fontSize: "20px",
            fontWeight: "700",
            color: "gray"
        },
        "& p": {
            fontSize: "18px",
            fontWeight: "600"
        },
    },
    mainIconCont: {
        width: "60px",
        height: "60px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: "green",
        borderRadius: "50%",
        marginRight: "15px",
        "& svg": {
            fontSize: "40px",
            color: "white"
        }
    },
    subTotalsCont: {
        width: "100%",
        display: "flex",
        overflowX: "auto",
        "&::-webkit-scrollbar": {
            display: "none",
        },
        scrollbarWidth: "none",
    },
    subTotalSingleCont: {
        display: "flex",
        alignItems: "center",
        marginRight: "10px",
        flexGrow: "1",
        flexShrink: "0",
        "& h3": {
            fontSize: "12px",
            fontWeight: "500",
            color: "gray"
        },
        "& p": {
            fontSize: "10px",
            fontWeight: "500"
        }
    },
    subIconSty: {
        width: "20px",
        height: "20px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: "blue",
        borderRadius: "50%",
        marginRight: "10px",
        "& svg": {
            fontSize: "12px",
            color: "white"
        }
    }
}));

export default function ProjectContactAnalysis({
    entity, curEntityId, secondPartyId
}) {
    const history = useHistory();
    const classes = useStyles();
    const dispatch = useDispatch();
    const theme = useTheme();

    const [aggregatedValuesPO, setAggregatedValuesPO] = useState(null)
    const [aggregatedValuesWO, setAggregatedValuesWO] = useState(null)
    const [loading, setLoading] = useState(false)

    const getAggregatedValuesForPurchaseOrder = async () => {
        setLoading(true)
        getProcurementsAggregatedAmounts({
            entity,
            curEntityId,
            type: "Purchase Order",
            vendorProfileId: secondPartyId
        })
            .then((data) => {
                console.log(data)
                setAggregatedValuesPO(data[0])
            })
            .catch((err) => {
                console.log(err)
            })
            .finally(() => {
                setLoading(false)
            })
    }

    const getAggregatedValuesForWorkOrder = async () => {
        setLoading(true)
        getProcurementsAggregatedAmounts({
            entity,
            curEntityId,
            type: "Work Order",
            vendorProfileId: secondPartyId
        })
            .then((data) => {
                console.log(data)
                setAggregatedValuesWO(data[0])
            })
            .catch((err) => {
                console.log(err)
            })
            .finally(() => {
                setLoading(false)
            })
    }

    useEffect(() => {
        if (curEntityId) {
            getAggregatedValuesForPurchaseOrder()
            getAggregatedValuesForWorkOrder()
        }
    }, [curEntityId])

    return (<>{loading ? (<div className={classes.loaderCont} >
        <CircularProgress />
    </div>) : (<div>
        <div className={classes.cardsCont} >
            <WorkorderPurchaseTotalCard
                cardType={"Purchase Order"}
                mainTotal={aggregatedValuesPO?.totalOpsOrderAmount || 0}
                draftAmount={aggregatedValuesPO?.draftAmount || 0}
                submittedAmount={aggregatedValuesPO?.submittedAmount || 0}
                approvedAmount={aggregatedValuesPO?.approvedAmount || 0}
                issuedAmount={aggregatedValuesPO?.issuedAmount || 0}
                acknowledgedAmount={aggregatedValuesPO?.acknowledgedAmount || 0}
                fullyFulfilledAmount={aggregatedValuesPO?.fullyFulfilledAmount || 0}
                partiallyFulfilledAmount={aggregatedValuesPO?.partiallyFulfilledAmount || 0}
                rejectedAmount={aggregatedValuesPO?.rejectedAmount || 0}
                certifiedAmount={aggregatedValuesPO?.certifiedAmount || 0}
                paidAmount={aggregatedValuesPO?.paidAmount || 0}
                retentionDeductionAmount={aggregatedValuesPO?.retentionDeductionAmount || 0} 
                retentionReleaseAmount={aggregatedValuesPO?.retentionReleaseAmount || 0} 
                advanceDeductionAmount={aggregatedValuesPO?.advanceDeductionAmount || 0}
            />
            <WorkorderPurchaseTotalCard
                cardType={"Workorder Order"}
                mainTotal={aggregatedValuesWO?.totalOpsOrderAmount || 0}
                draftAmount={aggregatedValuesWO?.draftAmount || 0}
                submittedAmount={aggregatedValuesWO?.submittedAmount || 0}
                approvedAmount={aggregatedValuesWO?.approvedAmount || 0}
                issuedAmount={aggregatedValuesWO?.issuedAmount || 0}
                acknowledgedAmount={aggregatedValuesWO?.acknowledgedAmount || 0}
                fullyFulfilledAmount={aggregatedValuesWO?.fullyFulfilledAmount || 0}
                partiallyFulfilledAmount={aggregatedValuesWO?.partiallyFulfilledAmount || 0}
                rejectedAmount={aggregatedValuesWO?.rejectedAmount || 0}
                certifiedAmount={aggregatedValuesWO?.certifiedAmount || 0}
                paidAmount={aggregatedValuesWO?.paidAmount || 0}
                retentionDeductionAmount={aggregatedValuesWO?.retentionDeductionAmount || 0} 
                retentionReleaseAmount={aggregatedValuesWO?.retentionReleaseAmount || 0} 
                advanceDeductionAmount={aggregatedValuesWO?.advanceDeductionAmount || 0}
            />
        </div>

        <AmountStatusChart
            cardType={"Purchase Order"}
            mainTotal={aggregatedValuesPO?.totalOpsOrderAmount || 0}
            draftAmount={aggregatedValuesPO?.draftAmount || 0}
            submittedAmount={aggregatedValuesPO?.submittedAmount || 0}
            approvedAmount={aggregatedValuesPO?.approvedAmount || 0}
            issuedAmount={aggregatedValuesPO?.issuedAmount || 0}
            acknowledgedAmount={aggregatedValuesPO?.acknowledgedAmount || 0}
            fullyFulfilledAmount={aggregatedValuesPO?.fullyFulfilledAmount || 0}
            partiallyFulfilledAmount={aggregatedValuesPO?.partiallyFulfilledAmount || 0}
            rejectedAmount={aggregatedValuesPO?.rejectedAmount || 0}
            certifiedAmount={aggregatedValuesPO?.certifiedAmount || 0}
            paidAmount={aggregatedValuesPO?.paidAmount || 0}
            retentionDeductionAmount={aggregatedValuesPO?.retentionDeductionAmount || 0} 
            retentionReleaseAmount={aggregatedValuesPO?.retentionReleaseAmount || 0}
            advanceDeductionAmount={aggregatedValuesPO?.advanceDeductionAmount || 0}
        />

        <AmountStatusChart
            cardType={"Workorder Order"}
            mainTotal={aggregatedValuesWO?.totalOpsOrderAmount || 0}
            draftAmount={aggregatedValuesWO?.draftAmount || 0}
            submittedAmount={aggregatedValuesWO?.submittedAmount || 0}
            approvedAmount={aggregatedValuesWO?.approvedAmount || 0}
            issuedAmount={aggregatedValuesWO?.issuedAmount || 0}
            acknowledgedAmount={aggregatedValuesWO?.acknowledgedAmount || 0}
            fullyFulfilledAmount={aggregatedValuesWO?.fullyFulfilledAmount || 0}
            partiallyFulfilledAmount={aggregatedValuesWO?.partiallyFulfilledAmount || 0}
            rejectedAmount={aggregatedValuesWO?.rejectedAmount || 0}
            certifiedAmount={aggregatedValuesWO?.certifiedAmount || 0}
            paidAmount={aggregatedValuesWO?.paidAmount || 0}
            retentionDeductionAmount={aggregatedValuesWO?.retentionDeductionAmount || 0} 
            retentionReleaseAmount={aggregatedValuesWO?.retentionReleaseAmount || 0}
            advanceDeductionAmount={aggregatedValuesWO?.advanceDeductionAmount || 0}
        />
    </div>)}</>);
}