import React, { useEffect, useState, useMemo } from "react";
import Dialog from "../../../styled/generic/Dialog";
import FormBox from "../../../styled/generic/FormBox";
import TextField from "../../../styled/generic/TextField";

import SpaceBetween from "../../../styled/generic/SpaceBetween";
import DatePicker from "../../../styled/generic/DatePicker";
import dayjs from "dayjs";
import DuoButtonGroup from "../../../styled/generic/DuoButtonGroup";
import { useSelector } from "react-redux";
import HorizBox from "../../../styled/generic/HorizBox";
import Select from "../../../styled/generic/Select";
import CloseIcon from "@mui/icons-material/Close";
import { makeStyles } from "@material-ui/core";
import {
  Autocomplete,
  Avatar,
  AvatarGroup,
  Box,
  Chip,
  IconButton,
  MenuItem,
  OutlinedInput,
  Stack,
  Tooltip,
  Typography,
  tooltipClasses,
  DialogTitle,
  DialogContent,
  DialogActions
} from "@mui/material";
import { Add, Delete, EditOff, Edit as EditIcon } from "@mui/icons-material";
import DocumentUploader from "../../../styled/generic/DocumentUploader";
import Api from "../../../../helpers/Api";
import { useDispatch } from "react-redux";
import DocViewer from "../../../styled/generic/DocViewer";
import LabelsDrawer from "./LabelsDrawer";
import UserPickerDropdown from "../../../styled/generic/UserPickerDropdown";
import BoxSpaceBetween from "../../../styled/generic/BoxSpaceBetween";
import DrawerContainer from "../../../styled/generic/DrawerContainer";
import CategoryCreateDrawer from "../../../OfferingsAndProducts/Category.Crearte.Drawer";
import Button from "../../../styled/generic/Button";
import useShareAndAssign from "../../../ResourceAccessControl/shareAndAssign";
import { updateWallet } from "../../../finance2o/accounts/paymentMethods/dwolla/api";
import { styled } from "@mui/material/styles";
import CustomAutocomplete from "../../../styled/CommonComponents/CustomAutocomplete";
import CategoryAutocomplete from "../../../OfferingsAndProducts/CategoryAutocomplete";
import CheckpointManager from "../../../OfferingsAndProducts/CheckpointManager";
import StandardAppContainerRounded from "../../../styled/generic/StandardAppContainerRounded";
import { getProgressColor } from "../../../../helpers/colorHelper";
import ProjectPicker from "../../../styled/generic/ProjectPicker";
import OrgPicker from "../../../styled/generic/OrgPicker";
import _ from "lodash";
import {
  Checkbox,
  FormControl,
  InputLabel,
  ListItemText,
} from "@material-ui/core";
import useBlockUnitViewer from "./BlockUnitViewer";
import NormalDrawer from "../../../styled/CommonComponents/NormalDrawer";
import { getSOPs, getChecklists, createChecklist, updateChecklist, addCheckpointToChecklist, removeCheckpointFromChecklist, getAllChecklists } from "../../../OfferingsAndProducts/Api.call";
import arrayToReducer from "../../../../helpers/arrayToReducer";
import DocPickerDrawer from "../../../doc/doc.picker.drawer";
// Import statement (adjust the path as needed)

// Assume all other imports remain the same

const StyledFormBox = styled(FormBox)(({ theme }) => ({
  '& .MuiTypography-root': {
    fontSize: '0.875rem',
    fontWeight: 600,
    marginBottom: theme.spacing(1),
  },
}));

const StyledSelect = styled(Select)(({ theme }) => ({
  '& .MuiSelect-select': {
    padding: theme.spacing(1),
  },
}));

const StyledTextField = styled(TextField)(({ theme }) => ({
  '& .MuiInputBase-root': {
    fontSize: '0.875rem',
  },
}));

const StyledDatePicker = styled(DatePicker)(({ theme }) => ({
  '& .MuiInputBase-root': {
    fontSize: '0.875rem',
  },
}));

const BootstrapTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} arrow classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.palette.common.black,
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.black,
  },
}));

const assignBtn = (
  <Button variant="outlined" startIcon={<Add />}>
    Click here to assign
  </Button>
);

function getLibraryId(library) {
  if (typeof library === 'object' && library !== null) {
    return library?._id;
  } else if (typeof library === 'string') {
    return library;
  }
  return null;
}

const useStyles = makeStyles((theme) => ({
  drawerBody: {
    width: "100%",
    padding: "20px",
    height: "calc(100% - 80px)",
    overflowY: "auto",
  },
  drawerBottomBtn: {
    width: "100%",
    height: "80px",
    paddingBottom: "20px",
    padding: "0px 20px",
  },
  organizationCover: {
    position: "relative",
    width: "100%",
  },
  editOrg: {
    width: "fit-content",
    position: "absolute",
    right: "1rem",
    bottom: "3rem",
  },
}));

const AddTaskDialog = ({
  defaultOrg,
  defaultProject,
  assigneesByCreater,
  showAddTaskDialog,
  setShowAddTaskDialog,
  targetId,
  onTaskAdd,
  onTaskUpdate,
  onTaskDelete,
  profileId,
  library,
  editBlocks,
  editUnits,
  project,
  organization,
  parentTaskId,
  taskToUpdate,
}) => {
  console.log("organization=", organization);
  console.log("taskToUpdate=", taskToUpdate);

  const classes = useStyles();
  const files = useSelector((state) => state.file);

  const { fileDictionary, createdFileIds } = files;
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.auth);
  const [taskName, setTaskName] = useState("");
  const [startDate, setStartDate] = useState(dayjs());
  const [endDate, setEndDate] = useState(dayjs().add(3, "day"));
  const [comment, setComment] = useState("");
  const [selectedProgressHistory, setSelectedProgressHistory] = useState();
  const [filesArr, setFilesArr] = useState([]);
  const [fileIds, setFileIds] = useState([]);


  const [selectedDocs, setSelectedDocs] = useState([]);
  const [showDocPicker, setShowDocPicker] = useState(false);

  const [selectedOrganization, setSelectedOrganization] = useState("");
  const [selectedProject, setSelectedProject] = useState("");
  const [labels, setLabels] = useState([]);

  const [sops, setSOPs] = useState([]);
  const [selectedSOPs, setSelectedSOPs] = useState([]);
  const [checklists, setChecklists] = useState([]);

  const [checkpointIds, setCheckpointIds] = useState([]);
  const [checkpointDict, setCheckpointDict] = useState({});
  const [openLabelsDrawer, setOpenLabelsDrawer] = useState(false);
  const [assigned, setAssigned] = useState([]);
  const [initialProfileRole, setInitialProfileRole] = useState({});
  const [loadingPrimary, setLoadingPrimary] = useState(false);
  const [loadingSecondary, setLoadingSecondary] = useState(false);
  const [removeBlockIds, setRemoveBlockIds] = useState([]);
  const [removeUnitIds, setRemoveUnitIds] = useState([]);
  const [showProgressHistoryDialog, setShowProgressHistoryDialog] =
    useState(false);
  const [selectedProgressHistoryFiles, setSelectedProgressHistoryFiles] =
    useState([]);
  const [selectUnits, setSelectUnits] = useState([]);
  const [category, setCategory] = useState(null);
  const [createCategoryDrawer, setCreateCategoryDrawer] = useState(false);
  const [addBlockIds, setAddBlockIds] = useState([]);
  const [showBlock, setShowBlock] = useState(true);
  const [removeFile, setRemoveFile] = useState({
    taskId: taskToUpdate?._id,
    removeFilesIds: [],
  });

  const projectProfileId = project?.profile;
  const orgProfileId = organization?.profile;
  const userProfileId = user?.profile;

  const [selectedChecklists, setSelectedChecklists] = useState([]);
  const [newChecklistTitle, setNewChecklistTitle] = useState("");
  const [newCheckpointDescription, setNewCheckpointDescription] = useState("");
  // Inside the CheckpointTreeView component
  const [observationDialogOpen, setObservationDialogOpen] = useState(false);
  const [selectedCheckpoint, setSelectedCheckpoint] = useState(null);


  console.log(taskToUpdate, '  taskToUpdateaddtaskdialog')


  useEffect(() => {
    let locInitialProfileRole = {};
    if (orgProfileId) {
      locInitialProfileRole[orgProfileId] = "Owner";
    }
    if (userProfileId) {
      locInitialProfileRole[userProfileId] = "Owner";
    }
    if (projectProfileId) {
      locInitialProfileRole[projectProfileId] = "Owner";
    }
    setInitialProfileRole(locInitialProfileRole);
  }, [orgProfileId, userProfileId, projectProfileId]);


  useEffect(() => {

    if (getLibraryId(library)) {
      fetchSOPs();
      fetchChecklists();
    }
  }, [library?._id, showAddTaskDialog]);


  // Add this function to handle document selection
  const handleDocSelection = (docs) => {
    setSelectedDocs(docs);
    // You might want to update filesArr and fileIds here as well
    const newFileIds = docs.flatMap(doc => doc.files.map(file => file._id));
    const newFiles = docs.flatMap(doc => doc.files);
    setFileIds(prevIds => [...new Set([...prevIds, ...newFileIds])]);
    setFilesArr(prevFiles => {
      const newFilesSet = new Set([...prevFiles, ...newFiles]);
      return Array.from(newFilesSet);
    });
  };

  const fetchSOPs = async () => {
    try {
      const res = await getSOPs({
        libraryId: getLibraryId(library),
      });
      let data = res?.data;

      if (Array.isArray(data)) {
        console.log('settingdata')
        setSOPs(data);
      }
    } catch (error) {
      console.error("Error fetching SOPs:", error);
    }
  };

  const fetchChecklists = async () => {
    try {
      const res = await getAllChecklists({
        libraryId: getLibraryId(library),
      });
      if (Array.isArray(res?.data)) {
        console.log(checklists, 'are the checklists')
        setChecklists(res.data);
      }
    } catch (error) {
      console.error("Error fetching checklists:", error);
    }
  };

  const {
    sharedProfilesRole,
    ShareButton,
    ShareDrawer,
    SharedView,
    assignedProfiles,
    AssignButton,
    AssigneDrawer,
    AssignView,
  } = useShareAndAssign({
    initShared: initialProfileRole,
    initAssigned: null,
    resourceId: taskToUpdate ? taskToUpdate._id : null,
    resourceName: taskToUpdate ? "WBS" : null,
    customShareBtn: null,
    customAssignBtn: assignBtn,
  });

  const [activeImageUploader, setActiveImageUploader] = useState(null);
  const { blockUnitView, selectedUnitIds, selectedBlockIds } =
    useBlockUnitViewer({
      projectId: selectedProject?._id || taskToUpdate?.project,
      initialBlockIds: taskToUpdate?.projectBlocks?.map(({ _id }) => _id) || [],
      initialUnitIds: taskToUpdate?.units?.map(({ _id }) => _id) || [],
    });

  const getAllUnitIds = () => {
    let allIds = [];

    for (let unitId in selectUnits) {
      allIds.push(...selectUnits[`${unitId}`].map(({ _id }) => _id));
    }
    return allIds;
  };

  const createTask = async () => {
    // console.log("sharedProfilesRole: ", sharedProfilesRole);
    // console.log("assignedProfiles: ", assignedProfiles);

    try {
      setLoadingPrimary(true);
      const { data } = await Api.post("/wbs/create-custom", {
        targetId: targetId,
        taskName: taskName,
        library: getLibraryId(library),
        startDate: startDate.toDate(),
        endDate: endDate.toDate(),
        comment: comment,
        docs: selectedDocs.map(doc => doc._id),
        files: filesArr,
        labels: labels?.map((l) => l?._id) || [],
        category: category?._id,
        creatorProfileId: user?.profile,
        creatorId: user?._id,
        parentTaskId: parentTaskId,
        blockIds: selectedBlockIds || [],
        unitIds: selectedUnitIds || [],
        organization: selectedOrganization?._id,
        project: selectedProject?._id || defaultProject?._id,
        sops: selectedSOPs.map((s) => s?._id),
        checkpoints: checkpointIds.map(checkpointId => checkpointDict[checkpointId]),
        shared:
          sharedProfilesRole && sharedProfilesRole.length > 0
            ? sharedProfilesRole.map((s) => s?._id)
            : [],
        assigned:
          assignedProfiles && assignedProfiles?.length > 0
            ? assignedProfiles.map((s) => s?._id)
            : [],
        accessRoles: sharedProfilesRole,
      });
      if (data) {
        dispatch({
          type: "AddApiAlert",
          payload: {
            success: true,
            message: "Task created successfully",
          },
        });
        setShowAddTaskDialog(false);
        setFileIds([]);
        setFilesArr([]);
        onTaskAdd(data);
      } else {
        dispatch({
          type: "AddApiAlert",
          payload: {
            success: false,
            message: "An unknown error occurred while creating task",
          },
        });
      }
    } catch (error) {
      dispatch({
        type: "AddApiAlert",
        payload: {
          success: false,
          message: "An unknown error occurred while creating task",
        },
      });
    } finally {
      setLoadingPrimary(false);
    }
  };

  const updateTask = async () => {
    try {
      setLoadingPrimary(true);
      const updateData = {
        _id: taskToUpdate?._id,
        updateBody: {
          taskName: taskName,
          removeFile: removeFile,
          startDate: startDate.toDate(),
          endDate: endDate.toDate(),
          comment: comment,
          files: filesArr,
          docs: selectedDocs.map(doc => doc._id),
          labels: labels?.map((l) => l?._id) || [],
          category: category?._id,
          creator: user?.profile,
          blockIds: selectedBlockIds || [],
          unitIds: selectedUnitIds || [],
          removeBlockIds: removeBlockIds,
          addBlockIds: addBlockIds,
          sops: selectedSOPs.map((s) => s?._id),
          library: getLibraryId(library),
          checkpoints: checkpointIds,
          shared:
            sharedProfilesRole && sharedProfilesRole.length > 0
              ? sharedProfilesRole.map((s) => s?._id)
              : [],
          assigned:
            assignedProfiles && assignedProfiles?.length > 0
              ? assignedProfiles.map((s) => s?._id)
              : [],
          organization: selectedOrganization?._id,
          project: selectedProject?._id,
          accessRoles: sharedProfilesRole,
        },
      }

      console.log(updateData, ' updateData');

      const { data } = await Api.post("/wbs/update-custom", updateData);
      if (data) {
        dispatch({
          type: "AddApiAlert",
          payload: {
            success: true,
            message: "Task updated successfully",
          },
        });
        onTaskUpdate(data);
        setShowAddTaskDialog(false);
        setFileIds([]);
        setFilesArr([]);
      } else {
        dispatch({
          type: "AddApiAlert",
          payload: {
            success: false,
            message: "An unknown error occurred while creating task",
          },
        });
      }
      // window.location.reload();
    } catch (error) {
      console.log(error);
      dispatch({
        type: "AddApiAlert",
        payload: {
          success: false,
          message: "An unknown error occurred while creating task",
        },
      });
    } finally {
      setLoadingPrimary(false);
    }
  };

  const [isDisabledOrg, setIsDisabledOrg] = useState(!selectedOrganization);
  const [isDisabledProject, setIsDisabledProject] = useState(!selectedProject);



  const deleteTask = async () => {
    try {
      setLoadingSecondary(true);
      const { data } = await Api.post("/wbs/delete", {
        taskIds: [taskToUpdate?._id],
      });

      dispatch({
        type: "AddApiAlert",
        payload: {
          success: true,
          message: "Task deleted successfully",
        },
      });

      setShowAddTaskDialog(false);
      onTaskDelete(taskToUpdate);
    } catch (error) {
      dispatch({
        type: "AddApiAlert",
        payload: {
          success: false,
          message: "An unknown error occurred while creating task",
        },
      });
    } finally {
      setLoadingSecondary(false);
    }
  };

  const handleSOPChange = (event) => {
    const newSOPIds = event.target.value;
    const newSOPs = sops.filter(sop => newSOPIds.includes(sop._id));
    setSelectedSOPs(newSOPs);
  };



  useEffect(() => {
    //console.log("taskToUpdate: ", taskToUpdate?.units);
    if (taskToUpdate?._id) {
      console.log("taskToUpdate?.labels-", taskToUpdate);

      let groupByBlocks = _.groupBy(taskToUpdate?.units, "projectBlock");
      // setBlocks(taskToUpdate?.projectBlocks);
      setSelectUnits(groupByBlocks);
      setSelectedDocs(taskToUpdate?.docs || []);
      setTaskName(taskToUpdate?.taskName);
      setStartDate(dayjs(taskToUpdate?.startDate));
      setEndDate(dayjs(taskToUpdate?.endDate));
      setComment(taskToUpdate?.comment);
      setLabels(taskToUpdate?.labels);
      setCategory(taskToUpdate?.category);
      setAssigned(taskToUpdate?.assigned || []);
      setSelectedSOPs(taskToUpdate?.sops || []);

      if (taskToUpdate.checkpoints) {
        const { idArr, newDict } = arrayToReducer(taskToUpdate.checkpoints);
        setCheckpointIds(idArr);
        setCheckpointDict(newDict);
      }
      let _docs = taskToUpdate?.docs || [];
      let _files = [];
      for (let i = 0; i < _docs?.length; i++) {
        let _doc = _docs[i];
        let _docFiles = _doc?.files || [];
        for (let j = 0; j < _docFiles?.length; j++) {
          let _docFile = _docFiles[j];
          _files.push(_docFile);
        }
      }

      setFilesArr(_files);
      setFileIds(_files.map((f) => f?._id) || []);

      if (taskToUpdate?.projectBlocks?.length && taskToUpdate?.units?.length) {
        let groupByBlocks = _.groupBy(taskToUpdate?.units, "projectBlock");

        setSelectUnits(groupByBlocks);
      }
    }
  }, [taskToUpdate]);

  console.log(taskToUpdate);

  return (
    <>
      <NormalDrawer
        openDrawer={showAddTaskDialog}
        setOpenDrawer={setShowAddTaskDialog}
        anchor={"right"}
        width={"60vw"}
        title={taskToUpdate ? "Update Task" : "Create Task"}
        content={
          <>
            <div className={classes.drawerBody}>
              <FormBox label="Task Name">
                <TextField
                  placeholder="Enter"
                  value={taskName}
                  onChange={(e) => setTaskName(e.target.value)}
                  fullWidth={true}
                />
              </FormBox>
              <SpaceBetween
                left={
                  <FormBox label="Start Date">
                    <DatePicker
                      fullWidth={true}
                      value={startDate}
                      onChange={setStartDate}
                    />
                  </FormBox>
                }
                right={
                  <FormBox label="End Date">
                    <DatePicker
                      fullWidth={true}
                      value={endDate}
                      onChange={setEndDate}
                    />
                  </FormBox>
                }
              />
              <SpaceBetween
                left={
                  <FormBox label="Organization">
                    <div className={classes.organizationCover}>
                      <OrgPicker
                        disabled={isDisabledOrg}
                        selectedOrg={selectedOrganization}
                        setSelectedOrg={setSelectedOrganization}
                        defaultOrganizationId={organization?._id}
                        hideLabel={true}
                        fullWidth={true}
                      />
                      {isDisabledOrg ? (
                        <EditIcon
                          sx={{ fontSize: "1rem" }}
                          className={classes.editOrg}
                          onClick={() => setIsDisabledOrg(!isDisabledOrg)}
                        />
                      ) : (
                        <EditOff
                          sx={{ fontSize: "1rem" }}
                          className={classes.editOrg}
                          onClick={() => setIsDisabledOrg(!isDisabledOrg)}
                        />
                      )}
                    </div>
                  </FormBox>
                }
                right={
                  <FormBox label="Project">
                    <div className={classes.organizationCover}>
                      <ProjectPicker
                        disabled={isDisabledProject}
                        defaultProjectId={project?._id}
                        selectedProject={selectedProject}
                        setSelectedProject={setSelectedProject}
                        fullWidth={true}
                      />
                      {isDisabledProject ? (
                        <EditIcon
                          sx={{ fontSize: "1rem" }}
                          className={classes.editOrg}
                          onClick={() => setIsDisabledProject(!isDisabledProject)}
                        />
                      ) : (
                        <EditOff
                          sx={{ fontSize: "1rem" }}
                          className={classes.editOrg}
                          onClick={() => setIsDisabledProject(!isDisabledProject)}
                        />
                      )}
                    </div>
                  </FormBox>
                }
              />
              {blockUnitView}

              <FormBox label="Documents">
                <Button
                  variant="outlined"
                  onClick={() => setShowDocPicker(true)}
                  startIcon={<Add />}
                >
                  Select Documents
                </Button>
                {selectedDocs.length > 0 && (
                  <Box mt={2}>
                    <Typography variant="subtitle1">Selected Documents:</Typography>
                    {selectedDocs.map(doc => (
                      <Chip
                        key={doc._id}
                        label={doc.title}
                        onDelete={() => setSelectedDocs(prev => prev.filter(d => d._id !== doc._id))}
                        style={{ margin: '4px' }}
                      />
                    ))}
                  </Box>
                )}
              </FormBox>

              {/* <FormBox label="Comment">
              <TextField
                placeholder="Enter"
                value={comment}
                onChange={(e) => setComment(e.target.value)}
                fullWidth={true}
                rows={4}
                multiline={true}
              />
            </FormBox> */}
              <FormBox label="Labels">
                <CategoryAutocomplete
                  libraryId={getLibraryId(library)}
                  selectedCategories={labels}
                  setSelectedCategories={setLabels}
                  isMultiple={true}
                  label={"Label"}
                  profileId={profileId}
                  type={"Task"}
                  isCreateNew={true}
                  placeholder="Select Label"
                />
              </FormBox>

              {/* <FormBox label="SOPs">
                <Select
                  fullWidth
                  multiple
                  value={selectedSOPs.map(sop => sop._id)}
                  onChange={handleSOPChange}
                  renderValue={(selected) => (
                    <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1 }}>
                      {selected.map((value) => {
                        const selectedItem = sops.find(sop => sop._id === value);
                        return (
                          <Chip
                            key={value}
                            label={selectedItem?.title || "Unknown"}
                            onDelete={() => {
                              setSelectedSOPs(prev => prev.filter(sop => sop._id !== value));
                            }}
                          />
                        );
                      })}
                    </Box>
                  )}
                >
                  {sops.map((sop) => (
                    <MenuItem key={sop._id} value={sop._id}>
                      {sop.title}
                    </MenuItem>
                  ))}
                </Select>
              </FormBox>
              <FormBox label="Selected SOPs">
                <Box sx={{ mt: 2 }}>
                  {selectedSOPs.map((sop, index) => (
                    <Box key={sop._id} sx={{ mb: 2, p: 2, border: '1px solid #e0e0e0', borderRadius: 2 }}>
                      <Typography variant="subtitle1" fontWeight="bold">
                        {index + 1}. {sop.title}
                      </Typography>
                      <Typography variant="body2">{sop.description}</Typography>
                      <Box sx={{ mt: 1 }}>
                        <Chip
                          label={`Version: ${sop.version}`}
                          size="small"
                          sx={{ mr: 1 }}
                        />
                        <Chip
                          label={`Status: ${sop.status}`}
                          size="small"
                        />
                      </Box>
                    </Box>
                  ))}
                </Box>
              </FormBox> */}

              {/* <CheckpointManager
                checkpointIds={checkpointIds}
                checkpointDict={checkpointDict}
                setCheckpointIds={setCheckpointIds}
                setCheckpointDict={setCheckpointDict}
                libraryId={library?.id}
                parentId={taskToUpdate?._id}
                parentModelName="WBS"
                checklists={checklists}
                profileId={projectProfileId ? projectProfileId : orgProfileId}
                isImageUploaderActive={activeImageUploader === 'checkpoint'}
                onImageUploaderActivate={() => setActiveImageUploader('checkpoint')}
                onImageUploaderDeactivate={() => setActiveImageUploader(null)}
              /> */}

              <FormBox label="Assignees">
                <Box sx={{ display: "flex", justifyContent: "start", my: 2 }}>
                  <AvatarGroup max={10}>
                    {assigneesByCreater?.map(({ parent }) => (
                      <BootstrapTooltip
                        placement="top"
                        title={parent?.displayName}
                      >
                        <Avatar
                          alt={parent?.displayName}
                          src={
                            parent?.displayPicture?.thumbUrl ||
                            parent?.displayPicture?.url
                          }
                        />
                      </BootstrapTooltip>
                    ))}
                  </AvatarGroup>
                </Box>
                {AssignButton} {AssigneDrawer}
                <div>{AssignView}</div>
              </FormBox>
              <FormBox label="Attachments">
                <DocumentUploader
                  documents={filesArr}
                  onUpload={() => {
                    setFileIds(createdFileIds);

                    let data = [];
                    for (let i = 0; i < createdFileIds?.length; i++) {
                      data.push(fileDictionary[createdFileIds[i]]);
                    }
                    let setValues = new Set([...filesArr, ...data]);
                    setFilesArr(Array.from(setValues));
                  }}
                  onDelete={(dataId) => {
                    setRemoveFile({
                      taskId: taskToUpdate?._id,
                      removeFilesIds: [...removeFile.removeFilesIds, dataId],
                    });
                    setFileIds(fileIds.filter((m) => m !== dataId));
                    setFilesArr(filesArr.filter((m) => m._id !== dataId));
                  }}
                  isImageUploaderActive={activeImageUploader != 'checkpoint'}
                  onImageUploaderActivate={() => setActiveImageUploader('document')}
                  onImageUploaderDeactivate={() => setActiveImageUploader(null)}
                />
                <Box>
                  {filesArr?.length > 0 ? (
                    <Box>
                      <DocViewer
                        data={filesArr}
                        isDeletable={true}
                        disableEditMetaData={true}
                        disableShowMetaData={true}
                        onMetadataChange={() => { }}
                        deleteFunction={(delId) => {
                          setRemoveFile({
                            taskId: taskToUpdate?._id,
                            removeFilesIds: [...removeFile.removeFilesIds, delId],
                          });
                          setFileIds(fileIds.filter((m) => m !== delId));
                          setFilesArr(filesArr.filter((m) => m._id !== delId));
                        }}
                      />
                    </Box>
                  ) : (
                    <Box>No documents found</Box>
                  )}
                </Box>
              </FormBox>
            </div>
            <div className={classes.drawerBottomBtn}>
              <DuoButtonGroup
                primaryButtonText={
                  taskToUpdate?._id ? "Update Task" : "Create Task"
                }
                primaryButtonListener={() => {
                  taskToUpdate?._id ? updateTask() : createTask();
                }}
                secondaryButtonText="Delete"
                secondaryButtonListener={() => deleteTask()}
                secondaryButtonColor="error"
                hideSecondary={!taskToUpdate?._id}
                loadingPrimary={loadingPrimary}
                loadingSecondary={loadingSecondary}
              />
            </div>
          </>
        }
      />
      {showDocPicker && (
        <DocPickerDrawer
          open={showDocPicker}
          setOpen={setShowDocPicker}
          onClose={() => setShowDocPicker(false)}
          initialProfileId={projectProfileId || orgProfileId || user?.profile}
          initialModelName={projectProfileId ? "Project" : (orgProfileId ? "Organization" : 'User')}
          onSelectionChange={handleDocSelection}
        />
      )}
    </>
  );
};

export default AddTaskDialog;