import React, { useEffect, useState } from "react";
import StandardContainer from "../styled/generic/StandardContainer";
import StandardAppContainerRounded from "../styled/generic/StandardAppContainerRounded";
import Api from "../../helpers/Api";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import { useSelector } from "react-redux";
import { getPopulatedTeamDataByIdForProject } from "./api.call";
import FormBox from "../styled/generic/FormBox";
import { Box, Grid, Typography } from "@mui/material";
import HorizBox from "../styled/generic/HorizBox";
import { FolderOutlined } from "@material-ui/icons";
import TableContainer from "../styled/generic/TableContainer";
import dayjs from "dayjs";
import { Dialog, DialogTitle, DialogContent, DialogActions, TextField, Button } from "@mui/material";
import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";

const ProjectUnit = () => {
  const { teamId } = useParams();
  const { teamDictionary, teamIds } = useSelector((state) => state.team);
  const [projectData, setProjectData] = useState(null);
  const [team, setTeam] = useState(null);
  const [projectBlocks, setProjectBlocks] = useState([]);
  const [rentalUnits, setRentalUnits] = useState([]);
  const [selectedBlock, setSelectedBlock] = useState(null);
  const [loadingUnits, setLoadingUnits] = useState(false);
  const [openEditDialog, setOpenEditDialog] = useState(false);
  const [unitToEdit, setUnitToEdit] = useState(null);
  const [editUnitData, setEditUnitData] = useState({
    name: "",
    unitType: "",
    area: "",
    saleableArea: "",
  });
  const [isSaving, setIsSaving] = useState(false);

  const handleEditUnit = (unit) => {
    setUnitToEdit(unit);
    setEditUnitData({
      name: unit.name || "",
      unitType: unit.unitType || "",
      area: unit.area || "",
      saleableArea: unit.saleableArea || "",
    });
    setOpenEditDialog(true);
  };
  
  const handleSaveUnit = async () => {
    setIsSaving(true);
    try {
      const updatedUnit = { ...unitToEdit, ...editUnitData };

      // Make API call to update unit details on the server
      const response = await Api.post("project/unit/update", { rentalUnit: updatedUnit });
      setRentalUnits((prevUnits) =>
        prevUnits.map((unit) => (unit._id === updatedUnit._id ? updatedUnit : unit))
      );
      setOpenEditDialog(false);
      setUnitToEdit(null);
 
    } catch (error) {
      console.error("Error updating unit:", error);
      // Optionally, you can show an error message to the user here
    } finally {
      setIsSaving(false);
    }
  };

  const getProjectData = async () => {
    if (teamDictionary[teamId]) {
      const teamData = teamDictionary[teamId];
      setTeam(teamData);
      setProjectData(teamData?.parent);
    } else {
      await getPopulatedTeamDataByIdForProject({ teamId: teamId })
        .then((updatedTeam) => {
          setTeam(updatedTeam);
          setProjectData(updatedTeam?.parent);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  const getProjectBlocks = async () => {
    try {
      const { data } = await Api.post("/public/project/block/get", {
        projectId: projectData?._id,
      });

      if (data) {
        setProjectBlocks(data);
        if (data.length > 0) {
          setSelectedBlock(data[0]);
        }
      }
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    getProjectData();
  }, []);

  useEffect(() => {
    if (projectData) {
      getProjectBlocks();
    }
  }, [projectData]);

  const getRentalUnitsByBlock = async (blockId) => {
    try {
      setLoadingUnits(true);
      const { data } = await Api.post("/project/unit/getUnitsByBlock", {
        blockId: blockId,
      });

      if (data) {
        console.log("units: ", data, ' blockId ', blockId);
        setRentalUnits(data);
      }
    } catch (err) {
      console.log(err);
    } finally {
      setLoadingUnits(false);
    }
  };

  useEffect(() => {
    if (selectedBlock) {
      getRentalUnitsByBlock(selectedBlock._id);
    }
  }, [selectedBlock]);

  return (
    <StandardContainer showAppBar={true} appBarTitle="Units">
      <StandardAppContainerRounded>
        <FormBox label="Blocks">
          <Grid container spacing={2}>
            {projectBlocks?.map((block) => (
              <Grid item xs={3} key={block._id}>
                <Box
                  sx={{
                    boxShadow:
                      selectedBlock?._id === block?._id
                        ? "0px 0px 6px 0px rgba(0,0,255,0.1)"
                        : "0px 0px 4px 0px rgba(0,0,0,0.05)",
                    borderRadius: "10px",
                    padding: "10px",
                    cursor: "pointer",
                  }}
                  onClick={() => setSelectedBlock(block)}
                >
                  <Typography variant="h6" gutterBottom>
                    {block?.name || "Untitled"}
                  </Typography>
                  <HorizBox>
                    <FolderOutlined />
                    <Typography variant="body1" gutterBottom>
                      {block?.unitCount || 0} Units
                    </Typography>
                  </HorizBox>
                </Box>
              </Grid>
            ))}
          </Grid>
        </FormBox>
      </StandardAppContainerRounded>
      <StandardAppContainerRounded>
        <FormBox label="Units">
              <TableContainer
                columns={["UNIT NAME", "CREATED DATE", "ACTIONS"]}
                data={rentalUnits}
                loading={loadingUnits}
              >
                {rentalUnits?.map((unit) => (
                  <tr key={unit._id}>
                    <td>{unit?.name || "Untitled"}</td>
                    <td>{dayjs(unit?.createdAt).format("DD-MM-YYYY")}</td>
                    <td>
                      <Button
                        variant="outlined"
                        size="small"
                        onClick={() => handleEditUnit(unit)}
                      >
                        Edit
                      </Button>
                    </td>
                  </tr>
                ))}
              </TableContainer>

        </FormBox>
      </StandardAppContainerRounded>
      <Dialog
  open={openEditDialog}
  onClose={() => setOpenEditDialog(false)}
  fullWidth
  maxWidth="sm"
>
  <DialogTitle>Edit Unit Details</DialogTitle>
  <DialogContent>
    {/* Unit Name */}
    <TextField
      label="Unit Name"
      value={editUnitData.name}
      onChange={(e) =>
        setEditUnitData({ ...editUnitData, name: e.target.value })
      }
      fullWidth
      margin="normal"
    />

    {/* Unit Type */}
    <FormControl fullWidth margin="normal">
      <InputLabel>Unit Type</InputLabel>
      <Select
        value={editUnitData.unitType}
        onChange={(e) =>
          setEditUnitData({ ...editUnitData, unitType: e.target.value })
        }
        label="Unit Type"
      >
        <MenuItem value="1BHK">1BHK</MenuItem>
        <MenuItem value="2BHK">2BHK</MenuItem>
        <MenuItem value="3BHK">3BHK</MenuItem>
        <MenuItem value="4BHK">4BHK</MenuItem>
        <MenuItem value="5BHK">5BHK</MenuItem>
        <MenuItem value="6BHK">6BHK</MenuItem>
        <MenuItem value="7BHK">7BHK</MenuItem>
      </Select>
    </FormControl>

    {/* Area */}
    <TextField
      label="Area"
      type="number"
      value={editUnitData.area}
      onChange={(e) =>
        setEditUnitData({ ...editUnitData, area: e.target.value })
      }
      fullWidth
      margin="normal"
    />

    {/* Saleable Area */}
    <TextField
      label="Saleable Area"
      type="number"
      value={editUnitData.saleableArea}
      onChange={(e) =>
        setEditUnitData({ ...editUnitData, saleableArea: e.target.value })
      }
      fullWidth
      margin="normal"
    />
  </DialogContent>
  <DialogActions>
    <Button onClick={() => setOpenEditDialog(false)} disabled={isSaving}>
      Cancel
    </Button>
    <Button
      onClick={handleSaveUnit}
      variant="contained"
      color="primary"
      disabled={isSaving}
    >
      {isSaving ? "Saving..." : "Save"}
    </Button>
  </DialogActions>
</Dialog>


    </StandardContainer>
  );
};

export default ProjectUnit;
