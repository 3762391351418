import {
  Avatar,
  AvatarGroup,
  Box,
  Button,
  IconButton,
  Slider,
  Tooltip,
  Typography,
  tooltipClasses,
} from "@mui/material";
import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { getProgressColor } from "../../../../helpers/colorHelper";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import BoxSpaceBetween from "../../../styled/generic/BoxSpaceBetween";
import HorizBox from "../../../styled/generic/HorizBox";
import DuoButtonGroup from "../../../styled/generic/DuoButtonGroup";
import { useDispatch, useSelector } from "react-redux";
import DocumentUploader from "../../../styled/generic/DocumentUploader";
import DocViewer from "../../../styled/generic/DocViewer";
import Api from "../../../../helpers/Api";
import DrawerContainer from "../../../styled/generic/DrawerContainer";
import SpaceBetween from "../../../styled/generic/SpaceBetween";
import FormBox from "../../../styled/generic/FormBox";
import TextField from "../../../styled/generic/TextField";
import SingleUserWithCaptionTableCell from "../../../styled/generic/SingleUserWithCaptionTableCell";
import TaskProgressUpdateDrawer from "./TaskProgressUpdateDrawer";
import { DeleteOutline, Edit, EditOutlined } from "@mui/icons-material";
import AddTaskDialog from "./AddTaskDialog";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import Popover from "@mui/material/Popover";
import { styled } from "@mui/material/styles";
import config from '../../../../config/index'
import MyPopOver from "../../../styled/CommonComponents/MyPopOver";


const useStyles = makeStyles((theme) => ({
  optionsCont: {
    padding: "5px",
  },
  typeSingleOptionSty: {
    padding: "4px 15px",
    fontSize: "16px",
    cursor: "pointer",
    "&:hover": {
      backgroundColor: "#ececec",
    },
  },
}));


const TaskCard = ({
  fullTaskView = false,
  task,
  tasks,
  setTask,
  setTasks,
  setEditUnits,
  setEditBlocks,
  disableBorder = false,
  disableElevation = false,
}) => {
  console.log('tasktask-', task);
  const classes = useStyles();
  const history = useHistory();
  const files = useSelector((state) => state.file);
  const { fileDictionary, createdFileIds } = files;
  const dispatch = useDispatch();
  const [showUpdateTaskDialog, setShowUpdateTaskDialog] = useState(false);
  const [taskToUpdate, setTaskToUpdate] = useState(null);
  const [workDoneToday, setWorkDoneToday] = useState(0);
  const [workLeft, setWorkLeft] = useState(0);
  const [comment, setComment] = useState("");
  const [documents, setDocuments] = useState([]);
  const [documentIds, setDocumentIds] = useState([]);
  const [showEditTaskDialog, setShowEditTaskDialog] = useState(false);

  const date = new Date(task?.createdAt);
  const day = date.getUTCDate().toString().padStart(2, "0");
  const month = (date.getUTCMonth() + 1).toString().padStart(2, "0"); // Months are zero-based
  const year = date.getUTCFullYear();
  const formattedDate = `${day}/${month}/${year}`;
  var isDev = config?.mode == 'dev'

  const BootstrapTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} arrow classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.arrow}`]: {
      color: theme.palette.common.black,
    },
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: theme.palette.common.black,
    },
  }));

  const deleteTask = async (task) => {
    try {
      const { data } = await Api.post(`/wbs/delete/`, {
        taskIds: [task._id],
      });

      setTasks((prev) => {
        return prev.filter((item) => item._id !== task._id);
      });

      dispatch({
        type: "AddApiAlert",
        payload: {
          success: true,
          message: "Task deleted successfully",
        },
      });
    } catch (err) {
      dispatch({
        type: "AddApiAlert",
        payload: {
          success: false,
          message: "An unknown error occurred while deleting task",
        },
      });
    }
  };

  return (
    <>
      <Box
        sx={{
          minWidth: "20rem",
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          backgroundColor: "white",
          p: 2,
          borderRadius: "12px",
          border: disableBorder ? "none" : "1px solid rgba(0,0,0,0.10)",
          boxShadow: disableElevation ? "none" : "0px 5px 10px 0px rgba(0, 0, 0, 0.10)",
          "&:hover": {
            boxShadow: "0px 5px 10px 0px rgba(0, 0, 0, 0.20)",
          }
        }}
      >
        <Box sx={{ width: "100%" }}>
          <Box
            sx={{
              paddingBottom: "20px",
              display: "flex",
              width: "100%",
              justifyContent: "space-between",
            }}
          >
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                columnGap: "1rem",
              }}
            >
              <Avatar
                alt={task?.creator?.parent?.displayName}
                src={task?.creator?.parent?.displayPicture?.url}
                sx={{ width: 42, height: 42 }}
              />
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "start",
                }}
              >
                <Typography variant="body1" sx={{ fontWeight: "bold" }}>
                  {task?.creator?.parent?.displayName}
                </Typography>
                <Typography
                  variant="body1"
                  sx={{ fontWeight: "semibold", fontSize: "0.9rem" }}
                >
                  {formattedDate}
                </Typography>
              </Box>
            </Box>

            <div>
              <MyPopOver
                closeOnClick={true}
                appearContent={
                  <Button
                    variant="text"
                  >
                    <MoreVertIcon />
                  </Button>
                }
                showContent={<div className={classes.optionsCont} >
                  <div
                    className={classes.typeSingleOptionSty}
                    onClick={() => {
                      setTaskToUpdate(task);
                      setShowEditTaskDialog(true);
                    }}
                  >
                    Edit
                  </div>
                  <div
                    className={classes.typeSingleOptionSty}
                    onClick={() => {
                      deleteTask(task);
                    }}
                  >
                    Delete
                  </div>
                </div>}
              />
            </div>
          </Box>
          <Box
            onClick={() => {
              if (isDev) {
                history.push(`/planning/task/${task?._id}`);
              } else if (config.mode == 'prod') {
                let link = `https://planning.reallist.ai/planning/task/${task?._id}`
                window.open(link, "_self");
              }
            }}
            style={{ cursor: "pointer" }}
          >
            <Typography
              variant="body1"
            >
              {!fullTaskView ? (
                <>
                  {String(task?.taskName).toUpperCase() ? (
                    String(task?.taskName).toUpperCase().length > 80 ? (
                      <>
                        {String(task?.taskName).toUpperCase().slice(0, 80)}.....
                      </>
                    ) : (
                      String(task?.taskName).toUpperCase()
                    )
                  ) : (
                    "Untitled"
                  )}
                </>
              ) : String(task?.taskName).toUpperCase() ? (
                String(task?.taskName).toUpperCase()
              ) : (
                "Untitled"
              )}
            </Typography>
            <Box sx={{ display: "flex", justifyContent: "start", mt: 1 }}>
              Assigned
              <AvatarGroup
                sx={{
                  "& .MuiAvatar-root": { width: 24, height: 24, fontSize: 15 },
                }}
                max={10}
              >
                {task?.assigned?.map(({ parent }) => (
                  <BootstrapTooltip placement="top" title={parent?.displayName}>
                    <Avatar
                      alt={parent?.displayName}
                      sx={{ width: 26, height: 26 }}
                      src={
                        parent?.displayPicture?.thumbUrl ||
                        parent?.displayPicture?.url
                      }
                    />
                  </BootstrapTooltip>
                ))}
              </AvatarGroup>
            </Box>
            <Box
              sx={{
                paddingBottom: "20px",
                display: "flex",
                flexDirection: 'column',
                width: "100%",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  width: "100%",
                  justifyContent: "space-between",
                }}
              >
                <Typography>Start: {new Date(task?.startDate).toLocaleDateString()}</Typography>
                <Typography>End: {new Date(task?.endDate).toLocaleDateString()}</Typography>
              </Box>
            </Box>
            <HorizBox>
              {task?.labels?.slice(0, 2).map((item) => (
                <div
                  style={{
                    backgroundColor: item?.colorCode + "33",
                    color: item?.colorCode,
                    padding: "4px 4px",
                    borderRadius: "10px",
                    fontSize: '1rem',
                  }}
                >
                  {item?.name}
                </div>
              ))}{" "}
              {task?.labels?.length > 2 ? (
                <div>+{task?.labels?.length - 2}</div>
              ) : null}
            </HorizBox>
          </Box>
          <Box
            sx={{
              backgroundColor: "#d1d5db",
              p: 1,
              borderRadius: "10px",
              mt: 1,
              position: "relative",
              zIndex: 1,
              height: "40px",
              display: "flex",
              alignItems: "center",
            }}
          >
            <Box
              sx={{
                width: `${task?.progress || 0}%`,
                backgroundColor: "white",
                height: "100%",
                borderRadius: "5px",
                backgroundColor: getProgressColor(task?.progress)
                  ?.backgroundColor,
                position: "absolute",
                top: 0,
                left: 0,
                bottom: 0,
              }}
            ></Box>
            <Box
              sx={{
                width: "100%",
                zIndex: 2,
              }}
            >
              <BoxSpaceBetween>
                <HorizBox>
                  <Typography
                    variant="h6"
                    sx={{
                      color: getProgressColor(task?.progress)?.color,
                    }}
                  >
                    {task?.progress || 0}%
                  </Typography>
                  <Typography
                    variant="subtitle2"
                    sx={{
                      color: getProgressColor(task?.progress)?.color,
                    }}
                  >
                    Progress
                  </Typography>
                </HorizBox>
                <Box>
                  <Button
                    sx={{
                      backgroundColor: "white",
                      color: getProgressColor(task?.progress)?.color,
                      width: "100px",
                      "&:hover": {
                        backgroundColor: "white",
                      },
                    }}
                    onClick={(e) => {
                      e.stopPropagation();
                      //setTaskToUpdate(task);
                      setShowUpdateTaskDialog(true);
                    }}
                  >
                    Update
                  </Button>
                </Box>
              </BoxSpaceBetween>
            </Box>
          </Box>
        </Box>
      </Box>
      <TaskProgressUpdateDrawer
        open={showUpdateTaskDialog}
        setOpen={setShowUpdateTaskDialog}
        setTasks={setTasks}
        setTask={setTask}
        taskToUpdate={task}
      />
      <AddTaskDialog
        assigneesByCreater={task?.assigned}
        showAddTaskDialog={showEditTaskDialog}
        setShowAddTaskDialog={setShowEditTaskDialog}
        targetId={task?.target?._id}
        onTaskUpdate={(newTask) => {
          const index = tasks?.findIndex((item) => item._id === newTask._id);
          const prevTask = tasks;
          if (index > -1) {
            prevTask[index] = newTask;
            setTasks(prevTask);
          }

        }}
        onTaskDelete={(deletedTask) => {
          const prevTask = tasks;
          setTasks(prevTask?.filter((item) => item._id !== deletedTask._id));
        }}
        profileId={task?.target?.organization?.profile}
        library={task?.target?.buildingCodeLibrary}
        project={task?.target?.project}
        organization={task?.target?.organization}
        taskToUpdate={taskToUpdate}
      />
    </>
  );
};

export default TaskCard;