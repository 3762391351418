import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import NormalDrawer from "../styled/CommonComponents/NormalDrawer";
import { makeStyles } from "@material-ui/core/styles";
import { IconButton, TextField as MUITextField } from "@material-ui/core";
import LinearProgress from "@material-ui/core/LinearProgress";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { mainRoles } from "../../helpers/contactHelper";
import AddIcon from "@material-ui/icons/Add";
import DeleteIcon from "@material-ui/icons/Delete";
import AllCommonField from "../styled/CommonComponents/AllCommonFields";
import {
  BasicUserData,
  BasicOrgData,
  BasicGroupData,
  prospectFields,
  customerFields,
  employeeFields,
  contractorFields,
  vendorFields,
  investorFields,
  lenderFields,
  tenantFields,
  workerFields,
  ownerFields,
  brokerFields,
} from "../../helpers/roleRelatedFields";
import {
  createPalApiCall,
  updatePalApiCall,
  getAllAdminProjectsAndOrgs,
} from "../styled/CommonComponents/api.call";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import {
  Box,
  FormControl,
  Grid,
  MenuItem,
  Stack,
  Typography,
} from "@mui/material";
import { Add } from "@mui/icons-material";
import Select from "../styled/generic/Select";
import useLocationForm from "../styled/hooks/useLocationForm";
import TextField from "../styled/generic/TextField";
import Button from "../styled/generic/Button";
import FormHeaderWithActions from "../styled/generic/FormHeaderWithActions";
import HorizBox from "../styled/generic/HorizBox";
import { Delete } from "@material-ui/icons";
import CircularProgress from '@material-ui/core/CircularProgress';
import AdminForm from "./Admin.Form";
import BrokerForm from "./Broker.Form";
import ContractorForm from "./Contractor.Form";
import CustomerForm from "./Customer.Form";
import EmployeeForm from "./Employee.Form";
import InvestorForm from "./Investor.Form";
import LenderForm from "./Lender.Form";
import OtherForm from "./Other.Form";
import OwnerForm from "./Owner.Form";
import ProspectForm from "./Prospect.Form";
import TenantForm from "./Tenant.Form";
import VendorForm from "./Vendor.Form";
import ViewerForm from "./Viewer.Form";
import WorkerForm from "./Worker.Form";
import { addNewRoleContact, getContactDetailsById, getContactDetailsByTypeAndBothProfile } from "./api.call";

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const REQUIREMENT_TYPE_OPTIONS = [
  "Rent",
  "Sell",
  "Roommate",
  "Job",
  "Investment",
  "Tender",
];

const FLOOR_OPTIONS = [
  { title: "Ground Floor", value: 0 },
  { title: "1st Floor", value: 1 },
  { title: "2nd Floor", value: 2 },
  { title: "3rd Floor", value: 3 },
  { title: "4th Floor", value: 4 },
  { title: "5th or More", value: -1 },
];

const useStyles = makeStyles((theme) => ({
  mainCont: {
    width: "100%",
    height: "calc(100% - 5px)",
    overflow: "hidden",
  },
  bodyCont: {
    width: "100%",
    height: "calc(100% - 50px)",
    overflow: "hidden",
    display: "flex",
    alignItems: "flex-start",
    justifyContent: "space-between",
  },
  formCont: {
    width: "52%",
    height: "100%",
    overflowX: "hidden",
    overflowY: "auto",
    padding: "15px 15px 20px",
    borderRight: "1px solid #ececec",
    "&::-webkit-scrollbar": {
      display: "none",
    },
  },
  directoryMainCont: {
    width: "48%",
    height: "100%",
    overflowX: "hidden",
    overflowY: "auto",
    padding: "15px 15px 0px",
    "&::-webkit-scrollbar": {
      display: "none",
    },
  },
  btnCont: {
    width: "100%",
    height: "50px",
    padding: "0px 7px",
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    borderTop: "1px solid #ececec",
  },
  roleAndTypeCont: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    "& h3": {
      fontSize: "18px",
      fontWeight: "500",
    }
  },
  allfieldsCont: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    flexWrap: "wrap",
  },
  divAndDeleteCont: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    marginTop: "20px",
  },
  profileTypeRoleCont: {
    width: "calc(100% - 45px)",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  imageNameCont: {
    width: "250px",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  nameUsernameCont: {
    width: "calc(100% - 35px)",
    "& h3": {
      fontSize: "16px",
      fontWeight: "500",
    },
    "& p": {
      fontSize: "14px",
      fontWeight: "300",
    },
  },
  directoryHeadCont: {
    width: "100%",
    height: "45px",
    display: "flex",
    alignItems: "flex-start",
    justifyContent: "space-between",
    borderBottom: "2px solid #ececec",
    "& h3": {
      fontSize: "18px",
      fontWeight: "600",
    },
  },
  allSelectedProfileCont: {
    width: "100%",
    height: "calc(100% - 47px)",
    overflow: "auto",
    "&::-webkit-scrollbar": {
      display: "none",
    },
  },
  loaderCont: {
    width: "100%",
    height: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  }
}));

const CreatePalDrawer = ({
  openPal,
  setOpenPal,
  title,
  financialRelationType,
  mainProfileWitParent,
  profileIdArr,
  selectEntityFunction,
  orgtype,
  actionType = "Create",
  notAllowedRoles = [],
  //to edit pal with palprofileId and type
  pal,
  contactType,
  //-------OR---------
  //to edit pal with financialRelationId(to create role fRelationId is needed)
  fRelationId
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.auth);
  const { locationForm, locations, setLocations } = useLocationForm("small");

  const [type, setType] = useState(orgtype || "Organization");
  const [roleType, setRoleType] = useState(financialRelationType || "Vendor");
  const [stateChange, setStateChange] = useState(false);
  const [basicDataWithField, setBasicDataWithField] = useState({});
  const [selectedProfileForRelation, setSelectedProfileForRelation] = useState([]);
  const [relationScopTypes, setRelationScopTypes] = useState([]);
  const [organizationOptions, setOrganizationOptions] = useState([]);
  const [projectOptions, setProjectOptions] = useState([]);
  const [loading, setLoading] = useState(false);
  const [editDataLoad, setEditDataLoad] = useState(false)
  const [warningMsg, setWarningMsg] = useState("");
  const [warningBool, setWarningBool] = useState(false);
  const [emails, setEmails] = useState([]);
  const [phoneNumbers, setPhoneNumbers] = useState([]);
  const [clients, setClients] = useState([]);
  const [source, setSource] = useState("Facebook");
  const [filteredRoles, setFilteredRoles] = useState({})
  const [prospectData, setProspectData] = useState({
    isPrivate: false,
    listing: null,
    title: "",
    requirementType: REQUIREMENT_TYPE_OPTIONS[0],
    minPrice: 0,
    maxPrice: 0,
    propertyType: null,
    minArea: 0,
    maxArea: 0,
    areaUnit: null,
    furnishings: [],
    bedRoomCount: 0,
    bathRoomCount: 0,
    balconyCount: 0,
    floors: [],
    amenities: []
  })
  const [customerData, setCustomerData] = useState({})
  const [employeeData, setEmployeeData] = useState({})
  const [contractorData, setContractorData] = useState({})
  const [vendorData, setVendorData] = useState({
    gst: "",
    pan: "",
    banks: [
      {
        _id: "",
        beneficiaryName: "",
        bankName: "",
        accountNumber: "",
        ifsc: "",
        branchName: ""
      }
    ]
  })
  const [investorData, setInvestorData] = useState({})
  const [lenderData, setLenderData] = useState({})
  const [tenantData, setTenantData] = useState({})
  const [workerData, setWorkerData] = useState({})
  const [brokerData, setBrokerData] = useState({})
  const [ownerData, setOwnerData] = useState({})
  const [viewerData, setViewerData] = useState({})
  const [adminData, setAdminData] = useState({})
  const [otherData, setOtherData] = useState({})
  const [editableContactData, setEditableContactData] = useState({})

  //to get options projects and organizations to select in directory
  useEffect(() => {
    if (openPal && profileIdArr && profileIdArr.length > 0) {
      getAllAdminProjectsAndOrgs({ givenProfiles: profileIdArr })
        .then((data) => {
          const organizationArr = data?.organizations || [];
          const projectArr = data?.projects || [];
          setOrganizationOptions(organizationArr);
          setProjectOptions(projectArr);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [mainProfileWitParent, openPal]);

  //to set default shared directory
  const changeSelectedProfileForRelationValue = () => {
    let obj = {
      type: mainProfileWitParent?.parentModelName === "User" ? "Myself" : mainProfileWitParent?.parentModelName,
      profile: mainProfileWitParent,
    };
    setSelectedProfileForRelation([obj]);
  };

  //to call function to set default shared directory
  useEffect(() => {
    if (openPal) {
      changeSelectedProfileForRelationValue();
    }
  }, [openPal, mainProfileWitParent?.parentModelName]);

  //to handle direcory entity types options("Myself", "Project", "Organization"), so if "Myself" alreday selected then we will not show it in options
  const changeRelationScopTypesValue = () => {
    let isMyself = false;
    selectedProfileForRelation.map((c) => { if (c.type === "Myself") isMyself = true; });
    if (isMyself) {
      setRelationScopTypes(["Project", "Organization"]);
    } else {
      setRelationScopTypes(["Myself", "Project", "Organization"]);
    }
  };

  //to call function handle direcory entity types options
  useEffect(() => {
    if (openPal) {
      changeRelationScopTypesValue();
    }
  }, [openPal, selectedProfileForRelation, stateChange]);

  //to change basic fileds base on entity types options
  useEffect(() => {
    if (openPal && type) {
      setStateChange(!stateChange);
      switch (type) {
        case "User":
          setBasicDataWithField(BasicUserData);
          break;
        case "Organization":
          setBasicDataWithField(BasicOrgData);
          break;
        case "Group":
          setBasicDataWithField(BasicGroupData);
          break;
      }
      setStateChange(!stateChange);
    }
  }, [type, openPal]);

  //to close popup message
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setWarningBool(false);
  };

  //filter roles
  useEffect(() => {
    if (openPal && notAllowedRoles.length > 0) {
      let locNotAllowedRoles = [...notAllowedRoles, "Owner", "Admin", "Viewer", "Other"]
      let locFilteredRoles = mainRoles.filter((s) => { if (!locNotAllowedRoles.includes(s)) return s });
      setFilteredRoles(locFilteredRoles)
    } else {
      setFilteredRoles(mainRoles)
    }
  }, [openPal, mainRoles, notAllowedRoles])

  //to set data for update function
  const setDataForEdit = async () => {
    //set basic data for update
    setEditDataLoad(true)
    let locContactData = null;
    if (fRelationId) {
      locContactData = await getContactDetailsById({
        fRelationId
      })
    } else if (pal?.profile?._id && contactType && mainProfileWitParent?._id) {
      locContactData = await getContactDetailsByTypeAndBothProfile({
        firstPartyProfileId: mainProfileWitParent?._id,
        secondPartyProfileId: pal?.profile?._id,
        type: contactType
      })
    }
    console.log(locContactData)
    setEditableContactData(locContactData)
    setType(locContactData?.profile?.parent?.type)
    setRoleType(locContactData?.type || "Customer");
    if (actionType === "Update") {
      let parent = locContactData?.profile?.parent;
      if (parent?.type === "User") {
        let displayName = parent?.displayName;
        let firstName = displayName.split(" ")[0];
        let middleName = displayName.split(" ")?.length > 2 ? displayName.split(" ")[1] : "";
        let lastName = displayName.split(" ")?.length > 2 ? displayName.split(" ")[2] : displayName.split(" ")[1];
        setBasicDataWithField({
          firstName: {
            type: "textField",
            value: firstName,
            lable: "First Name",
            width: "33.33%",
          },
          middleName: {
            type: "textField",
            value: middleName,
            lable: "Middle Name",
            width: "33.33%",
          },
          lastName: {
            type: "textField",
            value: lastName,
            lable: "Last Name",
            width: "33.33%",
          },
          mailId: {
            type: "textField",
            value: parent?.email,
            lable: "Mail Id",
            width: "50%",
          },
          phone: {
            type: "textField",
            value: parent?.phone,
            lable: "Phone No.",
            width: "50%",
          },
          city: {
            type: "textField",
            value: parent?.address?.city,
            lable: "City",
            width: "50%",
          },
          postalCode: {
            type: "textField",
            value: parent?.address?.postalCode,
            lable: "Postal Code",
            width: "50%",
          },
          state: {
            type: "textField",
            value: parent?.address?.state,
            lable: "State",
            width: "50%",
          },
          country: {
            type: "textField",
            value: parent?.address?.country,
            lable: "Country",
            width: "50%",
          },
          street: {
            type: "textField",
            value: parent?.address?.street,
            lable: "Street Address",
            width: "50%",
          },
          taxId: {
            type: "textField",
            value: parent?.taxId,
            lable: "Tax Id",
            width: "50%",
          },
          pan: {
            type: "textField",
            value: parent?.pan,
            lable: "Pan",
            width: "50%",
          },
        });
      } else if (parent?.type === "Organization") {
        setBasicDataWithField({
          displayName: {
            type: "textField",
            value: parent?.displayName,
            lable: "Name",
            width: "100%",
          },
          mailId: {
            type: "textField",
            value: parent?.email,
            lable: "Mail Id",
            width: "50%",
          },
          phone: {
            type: "textField",
            value: parent?.phone,
            lable: "Phone No.",
            width: "50%",
          },
          city: {
            type: "textField",
            value: parent?.address?.city,
            lable: "City",
            width: "50%",
          },
          postalCode: {
            type: "textField",
            value: parent?.address?.postalCode,
            lable: "Postal Code",
            width: "50%",
          },
          state: {
            type: "textField",
            value: parent?.address?.state,
            lable: "State",
            width: "50%",
          },
          country: {
            type: "textField",
            value: parent?.address?.country,
            lable: "Country",
            width: "50%",
          },
          street: {
            type: "textField",
            value: parent?.address?.street,
            lable: "Street Address",
            width: "50%",
          },
          pan: {
            type: "textField",
            value: parent?.pan,
            lable: "Pan",
            width: "50%",
          },
          taxId: {
            type: "textField",
            value: parent?.taxId,
            lable: "Tax Id",
            width: "50%",
          },
          website: {
            type: "textField",
            value: parent?.website,
            lable: "Website",
            width: "50%",
          },
        });
      } else if (parent?.type === "Group") {
        setBasicDataWithField({
          displayName: {
            type: "textField",
            value: parent?.displayName,
            lable: "Name",
            width: "100%",
          },
          mailId: {
            type: "textField",
            value: parent?.email,
            lable: "Mail Id",
            width: "50%",
          },
          phone: {
            type: "textField",
            value: parent?.phone,
            lable: "Phone No.",
            width: "50%",
          },
          hourlyRate: {
            type: "numberField",
            value: parent?.hourlyRate,
            lable: "hourly Rate",
            width: "50%",
          },
          costCode: {
            type: "numberField",
            value: parent?.costCode,
            lable: "Cost Code",
            width: "50%",
          },
          taxId: {
            type: "textField",
            value: parent?.taxId,
            lable: "Tax Id",
            width: "50%",
          }
        })
      }
      setEmails(parent?.emails || []);
      setPhoneNumbers(parent?.phoneNumbers || []);
      setClients(parent?.clients || []);

      //set contact data
      if (locContactData?.type === "Prospect") {
        let requirement = locContactData?.firstPartyContact?.requirement;
        let listingApp = locContactData?.firstPartyContact?.listingApp
        setProspectData({
          ...requirement,
          listing: listingApp?.listing,
          floors: requirement?.floors ? requirement.floors.map((s) => {
            let foolerObj = null;
            FLOOR_OPTIONS.map((t) => {
              if (t?.value === s) {
                foolerObj = t;
              }
            })
            return foolerObj;
          }) : []
        })
        setLocations(requirement?.locations || [])
      } else if (locContactData?.type === "Customer") {

      } else if (locContactData?.type === "Employee") {

      } else if (locContactData?.type === "Contractor") {

      } else if (locContactData?.type === "Vendor") {
        setVendorData({
          gst: locContactData?.firstPartyContact?.gstNo,
          pan: locContactData?.firstPartyContact?.panNo,
          banks: locContactData?.firstPartyContact?.banks || []
        })
      } else if (locContactData?.type === "Investor") {

      } else if (locContactData?.type === "Lender") {

      } else if (locContactData?.type === "Tenant") {

      } else if (locContactData?.type === "Worker") {

      } else if (locContactData?.type === "Broker") {

      } else if (locContactData?.type === "Owner") {

      } else if (locContactData?.type === "Viewer") {

      } else if (locContactData?.type === "Admin") {

      } else if (locContactData?.type === "Other") {

      }
    }
    let locShare = locContactData?.shared || []
    let oldShareArr = []
    locShare.map((shareProfile) => {
      oldShareArr.push({
        type: shareProfile?.parentModelName === "User" ? "Myself" : shareProfile?.parentModelName,
        profile: shareProfile
      })
    })
    setSelectedProfileForRelation(oldShareArr);
    setEditDataLoad(false)
  }

  //to set data for update
  useEffect(() => {
    if (openPal && (actionType === "Update" || actionType === "RoleAdd") && ((pal?.profile?._id && contactType && mainProfileWitParent?._id) || fRelationId)) {
      setDataForEdit()
    }
  }, [openPal, actionType, pal, contactType, fRelationId, mainProfileWitParent]);

  //to create pal
  const createPal = async (e) => {
    e.preventDefault();
    let basicDataObj = {};
    let basicData = {};
    let contactData = {};
    let locSelectedProfilesSet = new Set([]);
    let isAnyIssue = false;
    let msg = "";

    //Creating actual basic data object
    Object.keys(basicDataWithField).map((field) => {
      basicDataObj[field] = basicDataWithField[field]?.value;
    });
    //to validate basic data of pal
    if (type === "User" && (basicDataObj?.firstName == null || basicDataObj?.firstName == "" || basicDataObj?.firstName.length < 2)) {
      isAnyIssue = true;
      msg = "First name is required ";
    } else if ((type === "Organization" || type === "Group") && (basicDataObj?.displayName == null || basicDataObj?.displayName == "" || basicDataObj?.displayName.length < 3)) {
      isAnyIssue = true;
      msg = "Name is required ";
    }
    //to validate shared profile
    if (selectedProfileForRelation === null || selectedProfileForRelation.length === 0) {
      isAnyIssue = true;
      if (msg.length > 5) {
        msg = msg + "and have to selected minimum one entity for directory";
      } else {
        msg = msg + "Have to selected minimum one entity for directory";
      }
    }
    //return follow if any issue
    if (isAnyIssue) {
      setWarningMsg(msg);
      setWarningBool(isAnyIssue);
      return;
    }

    setLoading(true);
    //restructuring contact data 
    if (roleType === "Prospect") {
      contactData = {
        ...prospectData,
        owner: user.profile,
        source,
        locations: locations,
        floors: prospectData?.floors ? prospectData?.floors.map((item) => item.value) : []
      };
    } else if (roleType === "Customer") {
      contactData = {
        source,
        ...customerData
      };
    } else if (roleType === "Employee") {
      contactData = {
        source,
        ...employeeData
      };
    } else if (roleType === "Contractor") {
      contactData = {
        source,
        ...contractorData
      };
    } else if (roleType === "Vendor") {
      contactData = {
        source,
        ...vendorData
      };
    } else if (roleType === "Investor") {
      contactData = {
        source,
        ...investorData
      };
    } else if (roleType === "Lender") {
      contactData = {
        source,
        ...lenderData
      };
    } else if (roleType === "Tenant") {
      contactData = {
        source,
        ...tenantData
      };
    } else if (roleType === "Worker") {
      contactData = {
        source,
        ...workerData
      };
    } else if (roleType === "Broker") {
      contactData = {
        source,
        ...brokerData
      };
    } else if (roleType === "Owner") {
      contactData = {
        source,
        ...ownerData
      };
    } else if (roleType === "Viewer") {
      contactData = {
        source,
        ...viewerData
      };
    } else if (roleType === "Admin") {
      contactData = {
        source,
        ...adminData
      };
    } else if (roleType === "Other") {
      contactData = {
        source,
        ...otherData
      };
    }

    //restructure basic data to create pal
    if (type === "User") {
      basicData = {
        email: (basicDataObj?.mailId || "").toLowerCase(),
        displayName: `${basicDataObj?.firstName} ${basicDataObj?.middleName} ${basicDataObj?.lastName}`,
        address: {
          street: basicDataObj?.street,
          city: basicDataObj?.city,
          postalCode: basicDataObj?.postalCode,
          state: basicDataObj?.state,
          country: basicDataObj?.country,
        },
        phone: basicDataObj?.phone,
        type: type,
        parent: mainProfileWitParent?.parent,
        parentModelName: mainProfileWitParent?.parentModelName,
        contactModelName: roleType,
        user: user._id,
        taxId: basicDataObj?.taxId,
        pan: basicDataObj?.pan,
      };
    } else if (type === "Organization") {
      basicData = {
        type: type,
        address: {
          street: basicDataObj?.street,
          city: basicDataObj?.city,
          postalCode: basicDataObj?.postalCode,
          state: basicDataObj?.state,
          country: basicDataObj?.country,
        },
        displayName: basicDataObj?.displayName,
        email: (basicDataObj?.mailId || "").toLowerCase(),
        phone: basicDataObj?.phone,
        zip: basicDataObj?.postalCode || "",
        website: basicDataObj?.website,
        pan: basicDataObj?.pan,
        taxId: basicDataObj?.taxId,
        parent: mainProfileWitParent?.parent,
        parentModelName: mainProfileWitParent?.parentModelName,
        contactModelName: roleType,
        user: user._id
      };
    } else if (type === "Group") {
      basicData = {
        type: type,
        displayName: basicDataObj?.displayName,
        email: (basicDataObj?.mailId || "").toLowerCase(),
        phone: basicDataObj?.phone,
        costCode: basicDataObj?.costCode,
        hourlyRate: basicDataObj?.hourlyRate,
        parent: mainProfileWitParent?.parent,
        parentModelName: mainProfileWitParent?.parentModelName,
        contactModelName: roleType,
        user: user._id
      };
    }

    //to get shared profile
    selectedProfileForRelation.map((profileType) => {
      locSelectedProfilesSet.add(profileType?.profile?._id);
    });

    const requestObj = {
      contactData,
      basicData,
      roleType,
      locSelectedProfiles: [...locSelectedProfilesSet],
      currentEntityProfileId: mainProfileWitParent?._id,
    };

    await createPalApiCall(requestObj)
      .then((data) => {
        selectEntityFunction(data);
        //have to refactor
        setOpenPal(false);
        setType("User");
        setRoleType(roleType);
        setBasicDataWithField(BasicUserData);
        setWarningMsg("");
        setWarningBool(false);
        changeSelectedProfileForRelationValue();
        changeRelationScopTypesValue();
        //have to refactor
      })
      .catch((err) => {
        console.log(err);
        setWarningMsg("Something went wrong!!!");
        setWarningBool(true);
      })
      .finally(() => {
        setLoading(false);
      });
  }

  //to update pal(have to review)
  const updatePal = async (e) => {
    e.preventDefault();
    let basicDataObj = {};
    let basicData = {};
    let contactData = {};
    let locSelectedProfilesSet = new Set([]);
    let isAnyIssue = false;
    let msg = "";
    if (editableContactData?.profile?.parentModelName === "Pal") {
      //Creating actual basic data object
      Object.keys(basicDataWithField).map((field) => {
        basicDataObj[field] = basicDataWithField[field]?.value;
      });
      //to validate basic data of pal
      if (type === "User" && (basicDataObj?.firstName == null || basicDataObj?.firstName == "" || basicDataObj?.firstName.length < 2)) {
        isAnyIssue = true;
        msg = "First name is required ";
      } else if ((type === "Organization" || type === "Group") && (basicDataObj?.displayName == null || basicDataObj?.displayName == "" || basicDataObj?.displayName.length < 3)) {
        isAnyIssue = true;
        msg = "Name is required ";
      }
    }
    //to validate shared profile
    if (selectedProfileForRelation === null || selectedProfileForRelation.length === 0) {
      isAnyIssue = true;
      if (msg.length > 5) {
        msg = msg + "and have to selected minimum one entity for directory";
      } else {
        msg = msg + "Have to selected minimum one entity for directory";
      }
    }
    //return follow if any issue
    if (isAnyIssue) {
      setWarningMsg(msg);
      setWarningBool(isAnyIssue);
      return;
    }

    setLoading(true);
    //restructuring contact data 
    if (roleType === "Prospect") {
      contactData = {
        ...prospectData,
        source,
        locations: locations,
        floors: prospectData?.floors ? prospectData?.floors.map((item) => item.value) : []
      };
    } else if (roleType === "Customer") {
      contactData = {
        source,
        ...customerData
      };
    } else if (roleType === "Employee") {
      contactData = {
        source,
        ...employeeData
      };
    } else if (roleType === "Contractor") {
      contactData = {
        source,
        ...contractorData
      };
    } else if (roleType === "Vendor") {
      contactData = {
        source,
        ...vendorData,
        oldBankIds: editableContactData?.firstPartyContact?.banks ? editableContactData.firstPartyContact.banks.map((s) => s?._id) : []
      };
    } else if (roleType === "Investor") {
      contactData = {
        source,
        ...investorData
      };
    } else if (roleType === "Lender") {
      contactData = {
        source,
        ...lenderData
      };
    } else if (roleType === "Tenant") {
      contactData = {
        source,
        ...tenantData
      };
    } else if (roleType === "Worker") {
      contactData = {
        source,
        ...workerData
      };
    } else if (roleType === "Broker") {
      contactData = {
        source,
        ...brokerData
      };
    } else if (roleType === "Owner") {
      contactData = {
        source,
        ...ownerData
      };
    } else if (roleType === "Viewer") {
      contactData = {
        source,
        ...viewerData
      };
    } else if (roleType === "Admin") {
      contactData = {
        source,
        ...adminData
      };
    } else if (roleType === "Other") {
      contactData = {
        source,
        ...otherData
      };
    }
    //restructure basic data to create pal
    if (editableContactData?.profile?.parentModelName === "Pal") {
      if (type === "User") {
        basicData = {
          email: (basicDataObj?.mailId || "").toLowerCase(),
          displayName: `${basicDataObj?.firstName} ${basicDataObj?.middleName} ${basicDataObj?.lastName}`,
          address: {
            street: basicDataObj?.street,
            city: basicDataObj?.city,
            postalCode: basicDataObj?.postalCode,
            state: basicDataObj?.state,
            country: basicDataObj?.country,
          },
          phone: basicDataObj?.phone,
          type: type,
          parent: mainProfileWitParent?.parent,
          parentModelName: mainProfileWitParent?.parentModelName,
          contactModelName: roleType,
          pan: basicDataObj?.pan,
        };
      } else if (type === "Organization") {
        basicData = {
          type: type,
          address: {
            street: basicDataObj?.street,
            city: basicDataObj?.city,
            postalCode: basicDataObj?.postalCode,
            state: basicDataObj?.state,
            country: basicDataObj?.country,
          },
          displayName: basicDataObj?.displayName,
          email: (basicDataObj?.mailId || "").toLowerCase(),
          phone: basicDataObj?.phone,
          zip: basicDataObj?.postalCode || "",
          website: basicDataObj?.website,
          pan: basicDataObj?.pan,
          taxId: basicDataObj?.taxId,
          parent: mainProfileWitParent?.parent,
          parentModelName: mainProfileWitParent?.parentModelName,
          contactModelName: roleType,
        };
      } else if (type === "Group") {
        basicData = {
          type: type,
          displayName: basicDataObj?.displayName,
          email: (basicDataObj?.mailId || "").toLowerCase(),
          phone: basicDataObj?.phone,
          costCode: basicDataObj?.costCode,
          hourlyRate: basicDataObj?.hourlyRate,
          parent: mainProfileWitParent?.parent,
          taxId: basicDataObj?.taxId,
          parentModelName: mainProfileWitParent?.parentModelName,
          contactModelName: roleType,
        };
      }
    }

    //to get shared profile
    selectedProfileForRelation.map((profileType) => {
      locSelectedProfilesSet.add(profileType?.profile?._id);
    });

    const requestObj = {
      contactData,
      basicData,
      roleType,
      parentType: editableContactData?.profile?.parentModelName,
      locSelectedProfiles: [...locSelectedProfilesSet],
      currentEntityProfileId: mainProfileWitParent?._id,
      palId: editableContactData?.profile?.parent?._id,
      palProfileId: editableContactData?.profile?._id,
      relationId: editableContactData?._id,
      listingRequirementId: editableContactData?.firstPartyContact?.requirement?._id,
      listingAppId: editableContactData?.firstPartyContact?.listingApp?._id,
      contactId: editableContactData?.firstPartyContact?._id,
      walletId: editableContactData?.profile?.parent?.wallet
    };

    await updatePalApiCall(requestObj)
      .then((data) => {
        selectEntityFunction(data);
        //have to refactor
        setOpenPal(false);
        setType("User");
        setRoleType(roleType);
        setBasicDataWithField(BasicUserData);
        setWarningMsg("");
        setWarningBool(false);
        changeSelectedProfileForRelationValue();
        changeRelationScopTypesValue();
        //have to refactor
      })
      .catch((err) => {
        console.log(err);
        setWarningMsg("Something went wrong!!!");
        setWarningBool(true);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  //to add new contact with existing
  const addRoleCall = async (e) => {
    e.preventDefault();
    let contactData = {};
    let locSelectedProfilesSet = new Set([]);
    let isAnyIssue = false;
    let msg = "";

    //to validate shared profile
    if (selectedProfileForRelation === null || selectedProfileForRelation.length === 0) {
      isAnyIssue = true;
      if (msg.length > 5) {
        msg = msg + "and have to selected minimum one entity for directory";
      } else {
        msg = msg + "Have to selected minimum one entity for directory";
      }
    }
    //return follow if any issue
    if (isAnyIssue) {
      setWarningMsg(msg);
      setWarningBool(isAnyIssue);
      return;
    }

    setLoading(true);
    //restructuring contact data 
    if (roleType === "Prospect") {
      contactData = {
        ...prospectData,
        owner: user.profile,
        source,
        locations: locations,
        floors: prospectData?.floors ? prospectData?.floors.map((item) => item.value) : []
      };
    } else if (roleType === "Customer") {
      contactData = {
        source,
        ...customerData
      };
    } else if (roleType === "Employee") {
      contactData = {
        source,
        ...employeeData
      };
    } else if (roleType === "Contractor") {
      contactData = {
        source,
        ...contractorData
      };
    } else if (roleType === "Vendor") {
      contactData = {
        source,
        ...vendorData
      };
    } else if (roleType === "Investor") {
      contactData = {
        source,
        ...investorData
      };
    } else if (roleType === "Lender") {
      contactData = {
        source,
        ...lenderData
      };
    } else if (roleType === "Tenant") {
      contactData = {
        source,
        ...tenantData
      };
    } else if (roleType === "Worker") {
      contactData = {
        source,
        ...workerData
      };
    } else if (roleType === "Broker") {
      contactData = {
        source,
        ...brokerData
      };
    } else if (roleType === "Owner") {
      contactData = {
        source,
        ...ownerData
      };
    } else if (roleType === "Viewer") {
      contactData = {
        source,
        ...viewerData
      };
    } else if (roleType === "Admin") {
      contactData = {
        source,
        ...adminData
      };
    } else if (roleType === "Other") {
      contactData = {
        source,
        ...otherData
      };
    }

    //to get shared profile 
    selectedProfileForRelation.map((profileType) => {
      locSelectedProfilesSet.add(profileType?.profile?._id);
    });

    const requestObj = {
      contactData,
      roleType,
      locSelectedProfiles: [...locSelectedProfilesSet],
      currentEntityProfileId: mainProfileWitParent?._id,
      userId: user?._id,
      secondPartyProfileId: editableContactData?.profile?._id,
      secondPartyId: editableContactData?.parent?._id,
      secondPartyEntity: editableContactData?.parentModelName,
      walletId: editableContactData?.profile?.parent?.wallet
    };

    await addNewRoleContact(requestObj)
      .then((data) => {
        selectEntityFunction(data);
        //have to refactor
        setOpenPal(false);
        setType("User");
        setRoleType(roleType);
        setBasicDataWithField(BasicUserData);
        setWarningMsg("");
        setWarningBool(false);
        changeSelectedProfileForRelationValue();
        changeRelationScopTypesValue();
        //have to refactor
      })
      .catch((err) => {
        console.log(err);
        setWarningMsg("Something went wrong!!!");
        setWarningBool(true);
      })
      .finally(() => {
        setLoading(false);
      });
  }



  return (
    <NormalDrawer
      openDrawer={openPal}
      setOpenDrawer={setOpenPal}
      anchor={"right"}
      title={title ? title : "Add New Pal"}
      width={"85vw"}
      content={<>
        <div style={{ width: "100%", height: "5px" }}>
          {loading && <LinearProgress />}
        </div>
        {openPal && (
          <form
            className={classes.mainCont}
            onSubmit={(e) => {
              if (actionType === "RoleAdd") {
                addRoleCall(e)
              } else if (actionType === "Update") {
                updatePal(e);
              } else if (actionType === "Create") {
                createPal(e);
              }
            }}
          >
            <div className={classes.bodyCont}>
              {editDataLoad ? (<div className={classes.loaderCont} >
                <CircularProgress />
              </div>) : (<>
                <div className={classes.formCont} >
                  <div className={classes.roleAndTypeCont}>
                    {((actionType === "RoleAdd") || (actionType === "Update" && editableContactData?.profile?.parentModelName !== "Pal")) ? (<h3>{orgtype}</h3>) : (
                      <RadioGroup
                        row
                        aria-label="position"
                        name="position"
                        value={type}
                        onChange={(evt, val) => setType(val)}
                      >
                        <FormControlLabel
                          value="User"
                          control={<Radio color="primary" />}
                          label="User"
                        />
                        <FormControlLabel
                          value="Organization"
                          control={<Radio color="primary" />}
                          label="Organization"
                        />
                        <FormControlLabel
                          value="Group"
                          control={<Radio color="primary" />}
                          label="Group"
                        />
                      </RadioGroup>
                    )}
                    {actionType === "Update" ? (<h3>{roleType}</h3>) : (
                      <Autocomplete
                        size="small"
                        value={roleType}
                        options={filteredRoles}
                        getOptionLabel={(option) => option || ""}
                        getOptionSelected={(option) => {
                          return option === roleType;
                        }}
                        style={{ width: "200px" }}
                        onChange={(event, value) => {
                          setRoleType(value);
                        }}
                        renderInput={(params) => (
                          <MUITextField
                            {...params}
                            label={"Role"}
                            variant="outlined"
                            size="small"
                          />
                        )}
                      />
                    )}
                  </div>
                  <div className={classes.allfieldsCont}>
                    {(actionType === "Create" || (editableContactData?.profile?.parentModelName === "Pal" && actionType === "Update")) && (<>
                      {Object.keys(basicDataWithField).map((fieldData) => (
                        <AllCommonField
                          type={basicDataWithField[fieldData]?.type}
                          value={basicDataWithField[fieldData]?.value}
                          lable={basicDataWithField[fieldData]?.lable}
                          row={basicDataWithField[fieldData]?.row}
                          width={basicDataWithField[fieldData]?.width}
                          onChange={(field, value) => {
                            setStateChange(!stateChange);
                            const locObj = {
                              ...basicDataWithField,
                            };
                            locObj[field].value = value;
                            setBasicDataWithField(locObj);
                            setStateChange(!stateChange);
                          }}
                          fieldData={fieldData}
                          stateChange={stateChange}
                          setStateChange={setStateChange}
                        />
                      ))}
                      <Stack
                        sx={{
                          width: "100%",
                        }}
                        direction="column"
                        spacing={4}
                      >
                        <FormHeaderWithActions
                          label="Emails"
                          actions={
                            <HorizBox>
                              <Button
                                startIcon={<Add />}
                                variant="outlined"
                                onClick={() => setEmails([...emails, ""])}
                              >
                                Add Email
                              </Button>
                            </HorizBox>
                          }
                        >
                          {emails.map((email, index) => (
                            <HorizBox>
                              <TextField
                                key={index}
                                value={email}
                                onChange={(e) => {
                                  const newEmails = [...emails];
                                  newEmails[index] = e.target.value;
                                  setEmails(newEmails);
                                }}
                                size="small"
                                fullWidth={true}
                                placeholder={`Enter Email ${index + 1}`}
                                sx={{ mb: 1 }}
                              />
                              <IconButton
                                onClick={() => {
                                  const newEmails = [...emails];
                                  newEmails.splice(index, 1);
                                  setEmails(newEmails);
                                }}
                              >
                                <Delete />
                              </IconButton>
                            </HorizBox>
                          ))}
                        </FormHeaderWithActions>
                        <FormHeaderWithActions
                          label="Phone Numbers"
                          actions={
                            <HorizBox>
                              <Button
                                startIcon={<Add />}
                                variant="outlined"
                                onClick={() => setPhoneNumbers([...phoneNumbers, ""])}
                              >
                                Add Phone Number
                              </Button>
                            </HorizBox>
                          }
                        >
                          {phoneNumbers.map((phone, index) => (
                            <HorizBox>
                              <TextField
                                key={index}
                                value={phone}
                                onChange={(e) => {
                                  const newPhoneNumbers = [...phoneNumbers];
                                  newPhoneNumbers[index] = e.target.value;
                                  setPhoneNumbers(newPhoneNumbers);
                                }}
                                size="small"
                                fullWidth={true}
                                placeholder={`Enter Phone Number ${index + 1}`}
                                sx={{ mb: 1 }}
                              />
                              <IconButton
                                onClick={() => {
                                  const newPhoneNumbers = [...phoneNumbers];
                                  newPhoneNumbers.splice(index, 1);
                                  setPhoneNumbers(newPhoneNumbers);
                                }}
                              >
                                <Delete />
                              </IconButton>
                            </HorizBox>
                          ))}
                        </FormHeaderWithActions>
                        <FormHeaderWithActions
                          label="Clients"
                          actions={
                            <HorizBox>
                              <Button
                                startIcon={<Add />}
                                variant="outlined"
                                onClick={() => {
                                  setClients([...clients, { displayName: "", email: "", phone: "" }]);
                                }}
                              >
                                Add Client
                              </Button>
                            </HorizBox>
                          }
                        >
                          {clients.map((client, index) => (
                            <HorizBox
                              sx={{
                                alignItems: "start",
                              }}
                            >
                              <Grid sx={{ width: "100%" }} container spacing={1}>
                                <Grid item xs={6} key={index}>
                                  <TextField
                                    value={client.displayName}
                                    onChange={(e) => {
                                      const newClients = [...clients];
                                      newClients[index].displayName = e.target.value;
                                      setClients(newClients);
                                    }}
                                    size="small"
                                    fullWidth={true}
                                    placeholder={`Enter Client ${index + 1} Name`}
                                  />
                                </Grid>
                                <Grid item xs={6}>
                                  <TextField
                                    value={client.email}
                                    onChange={(e) => {
                                      const newClients = [...clients];
                                      newClients[index].email = e.target.value;
                                      setClients(newClients);
                                    }}
                                    size="small"
                                    fullWidth={true}
                                    placeholder={`Enter Client ${index + 1} Email`}
                                  />
                                </Grid>
                                <Grid item xs={6}>
                                  <TextField
                                    value={client.phone}
                                    onChange={(e) => {
                                      const newClients = [...clients];
                                      newClients[index].phone = e.target.value;
                                      setClients(newClients);
                                    }}
                                    size="small"
                                    fullWidth={true}
                                    placeholder={`Enter Client ${index + 1} Phone`}
                                  />
                                </Grid>
                                <Grid item xs={6}>
                                  <Box></Box>
                                </Grid>
                              </Grid>
                              <IconButton
                                onClick={() => {
                                  const newClients = [...clients];
                                  newClients.splice(index, 1);
                                  setClients(newClients);
                                }}
                              >
                                <Delete />
                              </IconButton>
                            </HorizBox>
                          ))}
                        </FormHeaderWithActions>
                      </Stack>
                    </>)}
                    {roleType === "Prospect" ? (
                      <ProspectForm
                        prospectData={prospectData}
                        setProspectData={setProspectData}
                        locationForm={locationForm}
                      />
                    ) : (<>{roleType === "Owner" ? (
                      <OwnerForm />
                    ) : (<>{roleType === "Customer" ? (
                      <CustomerForm
                        customerData={customerData}
                        setCustomerData={setCustomerData}
                      />
                    ) : (<>{roleType === "Employee" ? (
                      <EmployeeForm />
                    ) : (<>{roleType === "Contractor" ? (
                      <ContractorForm />
                    ) : (<>{roleType === "Vendor" ? (
                      <VendorForm
                        vendorData={vendorData}
                        setVendorData={setVendorData}
                      />
                    ) : (<>{roleType === "Investor" ? (
                      <InvestorForm />
                    ) : (<>{roleType === "Lender" ? (
                      <LenderForm />
                    ) : (<>{roleType === "Tenant" ? (
                      <TenantForm />
                    ) : (<>{roleType === "Worker" ? (
                      <WorkerForm />
                    ) : (<>{roleType === "Broker" ? (
                      <BrokerForm />
                    ) : (<>{roleType === "Admin" ? (
                      <AdminForm />
                    ) : (<>{roleType === "Viewer" ? (
                      <ViewerForm />
                    ) : (
                      <OtherForm />
                    )}</>)}</>)}</>)}</>)}</>)}</>)}</>)}</>)}</>)}</>)}</>)}</>)}
                    {/* <Box sx={{ width: "100%" }}>
                    <Box sx={{ width: "50%" }}>
                      <FormControl fullWidth>
                        <Typography
                          sx={{
                            fontWeight: 600,
                            marginBottom: "0.3rem",
                          }}
                        >
                          Source
                        </Typography>
                        <Select
                          sx={{ height: "2.5rem" }}
                          value={source}
                          //label="Source"
                          onChange={(e) => setSource(e.target.value)}
                        >
                          <MenuItem value={"Facebook"}>Facebook</MenuItem>
                          <MenuItem value={"Instagram"}>Instagram</MenuItem>
                          <MenuItem value={"Twitter"}>Twitter</MenuItem>
                          <MenuItem value={"Website"}>Website</MenuItem>
                        </Select>
                      </FormControl>
                    </Box>
                  </Box> */}
                  </div>
                </div>
                <div className={classes.directoryMainCont}>
                  <div className={classes.directoryHeadCont}>
                    <h3>Add In Directory</h3>

                    <Button
                      variant="contained"
                      size="small"
                      color="primary"
                      startIcon={<AddIcon />}
                      onClick={() => {
                        setStateChange(!stateChange);
                        const obj = {
                          type: "Project",
                          profile: null
                        };
                        let arr = [...selectedProfileForRelation];
                        arr.push(obj);
                        setSelectedProfileForRelation(arr);
                        setStateChange(!stateChange);
                      }}
                    >
                      Add New
                    </Button>
                  </div>

                  <div className={classes.allSelectedProfileCont}>
                    {selectedProfileForRelation &&
                      selectedProfileForRelation.map((profileTypeRole, i) => (
                        <div className={classes.divAndDeleteCont}>
                          <div className={classes.profileTypeRoleCont}>
                            <Autocomplete
                              size="small"
                              value={profileTypeRole?.type}
                              options={relationScopTypes}
                              getOptionLabel={(option) => option || ""}
                              getOptionSelected={(option) => {
                                return option === profileTypeRole?.type;
                              }}
                              style={{ width: "25%" }}
                              onChange={(event, value) => {
                                setStateChange(!stateChange);
                                let obj = {
                                  ...profileTypeRole,
                                };
                                if (value === "Myself") {
                                  const profileData = {
                                    _id: user?.profile,
                                    parent: user,
                                    parentModelName: "User",
                                  };
                                  obj.profile = profileData;
                                }
                                obj.type = value;
                                let arr = [...selectedProfileForRelation];
                                arr[i] = obj;
                                setSelectedProfileForRelation(arr);
                                setStateChange(!stateChange);
                              }}
                              renderInput={(params) => (
                                <MUITextField
                                  {...params}
                                  label={"Type"}
                                  variant="outlined"
                                  size="small"
                                />
                              )}
                            />

                            {profileTypeRole?.type === "Myself" ? (
                              <div></div>
                            ) : (
                              <>
                                {profileTypeRole?.type === "Project" ? (
                                  <Autocomplete
                                    size="small"
                                    value={profileTypeRole?.profile}
                                    options={projectOptions}
                                    getOptionLabel={(option) =>
                                      option?.parent?.displayName || ""
                                    }
                                    style={{ width: "60%" }}
                                    onChange={(event, value) => {
                                      setStateChange(!stateChange);
                                      let obj = {
                                        ...profileTypeRole,
                                      };
                                      obj.profile = value;
                                      let arr = [...selectedProfileForRelation];
                                      arr[i] = obj;
                                      setSelectedProfileForRelation(arr);
                                      setStateChange(!stateChange);
                                    }}
                                    renderInput={(params) => (
                                      <MUITextField
                                        {...params}
                                        label={"Project"}
                                        variant="outlined"
                                        size="small"
                                      />
                                    )}
                                  />
                                ) : (
                                  <Autocomplete
                                    size="small"
                                    value={profileTypeRole?.profile}
                                    options={organizationOptions}
                                    getOptionLabel={(option) =>
                                      option?.parent?.displayName || ""
                                    }
                                    style={{ width: "60%" }}
                                    onChange={(event, value) => {
                                      setStateChange(!stateChange);
                                      let obj = {
                                        ...profileTypeRole,
                                      };
                                      obj.profile = value;
                                      let arr = [...selectedProfileForRelation];
                                      arr[i] = obj;
                                      setSelectedProfileForRelation(arr);
                                      setStateChange(!stateChange);
                                    }}
                                    renderInput={(params) => (
                                      <MUITextField
                                        {...params}
                                        label={"Organization"}
                                        variant="outlined"
                                        size="small"
                                      />
                                    )}
                                  />
                                )}
                              </>
                            )}
                          </div>
                          <IconButton
                            onClick={() => {
                              let arr = [...selectedProfileForRelation];
                              arr.splice(i, 1);
                              setSelectedProfileForRelation(arr);
                            }}
                          >
                            <DeleteIcon style={{ fontSize: "20px" }} />
                          </IconButton>
                        </div>
                      ))}
                  </div>
                </div>
              </>)}
            </div>
            <div className={classes.btnCont}>
              <Button
                variant="contained"
                size="small"
                color="primary"
                type="submit"
              >
                {actionType === "Update" ? "Update & Close" : "Save & Close"}
              </Button>
            </div>
            <Snackbar
              open={warningBool}
              autoHideDuration={6000}
              onClose={handleClose}
              anchorOrigin={{ vertical: "top", horizontal: "right" }}
            >
              <Alert onClose={handleClose} severity="error">
                {warningMsg}
              </Alert>
            </Snackbar>
          </form>
        )}
      </>}
    />
  );
};

export default CreatePalDrawer;
