import { Paper } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import {
    BarChart,
    Bar,
    XAxis,
    YAxis,
    CartesianGrid,
    ResponsiveContainer,
    Tooltip,
    Legend,
    Rectangle,
} from "recharts";
import { makeStyles, useTheme } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
    gpMainCont: {
        width: "100%",
        padding: "20px",
        marginTop: "30px",
        "& .recharts-wrapper": {
            width: "100%"
        },
        "& h3": {
            fontSize: "18px",
            fontWeight: "600",
            marginBottom: "20px"
        }
    }
}));

export default function AmountStatusChart({
    cardType, mainTotal, draftAmount, submittedAmount,
    approvedAmount, issuedAmount, acknowledgedAmount,
    fullyFulfilledAmount, partiallyFulfilledAmount,
    rejectedAmount, certifiedAmount, paidAmount,
    retentionDeductionAmount, advanceDeductionAmount,
    retentionReleaseAmount
}) {
    const classes = useStyles();

    const [mapData, setMapData] = useState([])

    useEffect(() => {
        setMapData([
            {
                name: "Draft",
                amount: draftAmount
            },
            {
                name: "Submitted",
                amount: submittedAmount
            },
            {
                name: "Approved",
                amount: approvedAmount
            },
            {
                name: "Retention Deduction",
                amount: retentionDeductionAmount
            },
            {
                name: "Retention Release",
                amount: retentionReleaseAmount
            },
            {
                name: "Advance Deduction",
                amount: advanceDeductionAmount
            },
            {
                name: "Issued",
                amount: issuedAmount
            },
            {
                name: "Acknowledged",
                amount: acknowledgedAmount
            },
            {
                name: "Fully Fulfilled",
                amount: fullyFulfilledAmount
            },
            {
                name: "Partially Fulfilled",
                amount: partiallyFulfilledAmount
            },
            {
                name: "Rejected",
                amount: rejectedAmount
            },
            {
                name: "Certified",
                amount: certifiedAmount
            },
            {
                name: "Paid",
                amount: paidAmount
            },
        ]);
    }, [draftAmount, submittedAmount, retentionReleaseAmount,
        approvedAmount, issuedAmount, acknowledgedAmount,
        fullyFulfilledAmount, partiallyFulfilledAmount,
        rejectedAmount, certifiedAmount, paidAmount,
        retentionDeductionAmount, advanceDeductionAmount])

    function numberWithCommas(x) {
        return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }

    return (
        <Paper elevation={2} className={classes.gpMainCont}  >
            <h3>{cardType} Distribution (₹{numberWithCommas(parseFloat(mainTotal).toFixed(2))})</h3>
            <div style={{ width: '100%', height: '100%' }}>
                <ResponsiveContainer width="100%" height={400}> {/* Adjust the height if needed */}
                    <BarChart
                        data={mapData}
                        margin={{
                            top: 5,
                            right: 30,
                            left: 20,
                            bottom: 5,
                        }}
                    >
                        <CartesianGrid strokeDasharray="3 3" />
                        <XAxis dataKey="name" />
                        <YAxis />
                        <Tooltip />
                        <Bar
                            dataKey="amount"
                            fill="#B3CDAD"
                            activeBar={<Rectangle fill="pink" stroke="blue" />}
                        />
                    </BarChart>
                </ResponsiveContainer>
            </div>
        </Paper>
    );
}
